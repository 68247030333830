<mat-expansion-panel class="footer-seo" #mapanel (opened)="scrollDown()" [ngClass]="{'mb-111': addMarginBottom, 'mt-42': addMarginTop, 'mb-54': whatsappWidgetSpace }" [expanded]="!utilService.isBrowser">
  <mat-expansion-panel-header [ngClass]="{'mb-20':mainLayoutConfig?.enableDock && isMobile }">
    <mat-panel-title class="title">
      <h3 class="title m-0 f-bold">
        {{ footerSeoContent?.mainTitle }}
      </h3>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="footer-data" id="footer-seo">
    <section class="section" *ngFor="let data of footerSeoContent?.sections">
      <h2 class="heading" [innerHTML]="data?.heading" *ngIf="data?.heading">
      </h2>
      <h3 class="sub-heading" [innerHTML]="data?.subHeading">
      </h3>
      <p class="desc" [innerHTML]="data?.desc">
      </p>
      <section *ngFor="let val of data?.children" class="section">
        <h4 class="f-17" [innerHTML]="val?.heading"></h4>
        <p class="desc" [innerHTML]="val?.desc"></p>
        <section class="section" *ngIf="isNumericFlow()">
          <ul class="f-17" *ngIf="val?.bullet;else numericFlow">
            <li *ngFor="let item of val?.list">
              <h5 class="p-0 f-14" [innerHTML]="item?.title" ></h5>
              <p *ngIf="item.desc" [innerHTML]="item.desc"></p>
            </li>
          </ul>
          <ng-template #numericFlow>
            <ol class="f-17">
              <li *ngFor="let data1 of val?.list">
                <h5 [innerHTML]="data1?.title" class="f-14"></h5>
                <p [innerHTML]="data1?.desc"></p>
              </li>
            </ol>
          </ng-template>

          <p class="desc" *ngIf="val?.desc2" [innerHTML]="val?.desc2"></p>
        </section>
        <ng-container *ngIf="url === '/cause/medical-fundraising'">
          <section class="section" *ngFor="let data2 of val?.list">
            <h5 class="f-14" [innerHTML]="data2?.title">
            </h5>
            <p class="desc" [innerHTML]="data2?.desc"></p>
            <ol class="section list">
              <li class="f-17" *ngFor="let val of data2.list" [innerHTML]="val?.val"></li>
            </ol>
            <p class="desc" [innerHTML]="data2.desc2"></p>
          </section>
        </ng-container>
        <ng-container *ngIf="url==='/cause/cancer-crowdfunding'">
          <ul>
            <li class="f-17" *ngFor="let item of val?.list">
              {{ item.title}}
            </li>
          </ul>
        </ng-container>    
      </section>
    </section>
    <section class="section" *ngIf="footerSeoContent?.qs">
      <div class="wrapper-qa">
        <div class="left-col">
          Q.
        </div>
        <div class="right-col">
          <div class="item" *ngFor="let item of footerSeoContent?.qs" [innerHTML]="item?.title"></div>
        </div>
      </div>
      <div class="wrapper-qa" *ngIf="footerSeoContent?.as">
        <div class="left-col">
          A.
        </div>
        <div class="right-col">
          <div class="item" *ngFor="let item of footerSeoContent?.as" [innerHTML]="item?.title"></div>
        </div>
      </div>
    </section>
    <section class="search text-center" *ngIf="footerSeoContent?.searches">
      <div class="title mb-3 f-bold">
        Popular Searches
      </div>
      <div class="data" [innerHTML]="footerSeoContent?.searches?.data">
      </div>
    </section>
  </div>

</mat-expansion-panel>
