<mat-expansion-panel class="footer-seo" #mapanel (opened)="scrollDown()" [expanded]="!util.isBrowser">
  <mat-expansion-panel-header>
    <mat-panel-title class="title">
      <h3 class="title m-0 f-bold" [innerHTML]="footerSeoContent?.title">
      </h3>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="footer-data" id="footer-seo" [innerHTML]="footerSeoContent?.content">
  </div>
</mat-expansion-panel>
