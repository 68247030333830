import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-close-btn',
  templateUrl: './close-btn.component.html',
  styleUrls: ['./close-btn.component.scss']
})
export class CloseBtnComponent {

  @Input() top;
  @Input() right;
  @Input() bottom;
  @Input() left;
  @Input() type;
  @Output() closeBtnListner = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    public sheet: MatBottomSheet
  ) { }

  close() {
    this.closeBtnListner.next(true);
    switch (this.type) {
      case 'dialog': this.dialog.closeAll(); break;
      case 'sheet': this.sheet.dismiss(); break;
      default: this.dialog.closeAll(); break;
    }
  }

}
