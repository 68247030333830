import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Local storage service manages all the access
 * retrival from or to the localstorage of the
 * browser
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * Insert or add dada into local storage
   * @param name, @param data
   */
  add(name, data) {
    if (typeof window !== 'undefined') {
      try {
        localStorage.setItem(name, JSON.stringify(data));
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * Get the data from localstorage
   * @param name
   */
  get(name) {
    if (typeof window !== 'undefined') {
      let data;
      try {
        data = JSON.parse(localStorage?.getItem(name));
      } catch (error) {
        data = null;
      }
      return data;
    }
  }
  /**
   * Get signle entity
   * @param parent @param child
   */
  getProperty(parent, child) {
    if (typeof window !== 'undefined') {
      try {
        const data = JSON.parse(localStorage.getItem(parent));
        return data ? data[child] : false;
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**Get the user type */
  getParentCauseId() {
    if (typeof window !== 'undefined') {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        switch (user.user.user_type) {
          case 'individual':
            return '20';
          case 'ngo':
            return '40';
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  /**
   * Check the localstorage if already exist
   * it will delete it and replace it with
   * new response
   * @param name, @param data
   */
  check(name, data) {
    if (this.get(name)) {
      this.delete(name);
      this.add(name, data);
    } else {
      this.add(name, data);
    }
  }
  /**
   * Delete the data present in localstorage
   * @param name
   */
  delete(name) {
    if (typeof window !== 'undefined') {
      try {
        localStorage.removeItem(name);
      } catch (error) {
        console.log(error);
      }
    }
  }
  /**
   * Get the data from session storage
   * @param name
   */
  getFromSession(name) {
    if (typeof window !== 'undefined') {
      try {
        const data = JSON.parse(sessionStorage.getItem(name));
        return data ? data : false;
      } catch(e) {
        console.log(e);
      }
    }
  }
  /**
   * delete from session storage
   */
  deleteFromSession(name) {
    if (typeof window !== 'undefined') {
      try {
        sessionStorage.removeItem(name);
      } catch(e) {
        console.log(e);
      }
    }
  }
  /**
   * Check the session if already exist
   * it will delete it and replace it with
   * new response
   * @param name, @param data
   */
  checkFromSession(name, data) {
    if (this.getFromSession(name)) {
      this.deleteFromSession(name);
      this.addSessionData(name, data);
    } else {
      this.addSessionData(name, data);
    }
  }
  /**
   * Insert or add dada into session storage
   * @param name, @param data
   */
  addSessionData(name, data) {
    if (typeof window !== 'undefined') {
      try {
        sessionStorage.setItem(name, JSON.stringify(data));
      } catch(e) {
        console.log(e);
      }
    }
  }
  /**
   * Get single entity from session
   * @param parent @param child
   */
  getPropertyFromSession(parent, child) {
    if (typeof window !== 'undefined') {
      try {
        const data = JSON.parse(sessionStorage.getItem(parent));
        return data ? data[child] : false;
      } catch(e) {
        console.log(e);
      }
    }
  }
}
