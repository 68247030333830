import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZohoChatComponent } from './zoho-chat.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  declarations: [ZohoChatComponent],
  exports: [ZohoChatComponent],
})
export class ZohoChatModule { }
