export const FOOTER_SEO_CONTENT_HOME = {
  mainTitle: 'Start Your Crowdfunding Journey With Ketto',
  sections: [
    {
      id: 1,
      heading: 'Ketto: Leading Crowdfunding and Fundraising Website in India',
      subHeading: '',
      desc: `Are you familiar with the concept of <strong>online crowdfunding? India,</strong> over the last few years, has seen a number of <strong>crowdfunding platforms</strong> come up. <br><br>In <strong>India, crowdfunding</strong> is not a new concept. During the olden days, people would go door to door asking their neighbours for monetary help. When it comes to <strong>fundraising in India</strong>, this method was time-consuming, but those who were <strong>raising funds</strong> were able to create a personal bond with those who were helping them. They would visit their neighbours, share their stories and explain why they needed funds. Today, there are a number of <strong>crowdfunding websites in India,</strong> and people can raise funds online.`,
      children: null
    },
    {
      id: 2,
      heading: 'What is Ketto?',
      subHeading: '',
      desc: `<p>Do you want to start a <strong>crowdfunding campaign</strong> or <strong>crowdfund</strong>? India has a number of <strong>crowdfunding websites</strong> on which you can start a fundraiser. <strong>Indian crowdfunding platforms</strong> help the campaigner connect to a wider audience and raise funds smoothly. These <strong>fundraising websites</strong> also have features like easy fundraiser creation, secure payments and marketing tools, which allow you to reach your fundraising goals.</p><p><strong>Ketto Online Ventures Pvt. Ltd.</strong> is an online <strong>crowdfunding platform in India</strong> that helps individuals and organisations in the country raise funds for a cause that means something to them. It doesn’t charge any platform fee*, which makes it easier for campaigners, i.e. people who have started a fundraiser, to <strong>raise funds online</strong>. Whether you need funds for medical care, or to support an NGO, Ketto can be your go-to <strong>website to raise funds</strong>.</p>`,
      children: null
    },
    {
      id: 3,
      heading: 'What Can You Fundraise Online For?',
      subHeading: '',
      desc: `You can <a target="_blank"href="https://www.ketto.org/new/crowdfunding?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">raise funds online</a> for a number of causes on <strong>Ketto.</strong> What is the most common cause or <strong>fundraiser</strong> for which people raise money? <strong>Medical fundraising</strong> is the most common, which helps patients with cancer treatment, heart disease, kidney failure, and more. You can also support NGOs focused on environmental conservation, child rights, women's rights, poverty, and human rights. We connect generous people with those who need help.`,
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'Beginnings',
      desc: `Founded in 2012 by Varun Seth, Kunal Kapoor and Zaheer Adenwala, <strong>Ketto</strong> is a <strong>crowdfunding platform</strong> based in India. Ketto has helped countless individuals and organisations in <strong>raising funds</strong> since it began. <br><br><strong>Ketto Meaning</strong>: The word <strong>'Ketto'</strong> means 'Key To Tomorrow,' and we live by our mission to help people get the resources they need for better health and happiness.`,
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'Our Mission and Vision',
      desc: 'Our mission is to connect people to access healthcare and share happiness. Our vision is “Healthcare for All.” We believe that it is important for everyone to get access to necessary medical care despite their financial situation.',
      children: null
    },
    {
      id: 6,
      heading: 'Ketto HealthFirst and Ketto SIP',
      subHeading: '',
      desc: `<strong>Ketto HealthFirst: Ketto</strong> has partnered with Medibuddy to start <a target="_blank"href="https://www.ketto.org/healthfirst?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">HealthFirst</a>, a healthcare plan that aims to provide people with easier solutions to their healthcare needs. This plan offers people a better approach to managing their healthcare plans.
            <br><br><strong>Ketto SIP</strong>: <a target="_blank"href="https://www.ketto.org/new/sip?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">Ketto SIP</a> is an initiative we started wherein supporters can donate as little as Rs 100 a month to a cause close to their hearts. They can support <strong>fundraisers</strong> and contribute towards a child's education or help feed underprivileged children.`,
      children: null
    },
    {
      id: 7,
      heading: 'How Can You Start a Fundraiser on Ketto or How To Raise Funds On Ketto:',
      subHeading: '',
      desc: `Starting a <strong>crowdfunding</strong> campaign on <strong>www.ketto.org</strong> is an easy 4 step process : <br><br>
            1. <strong>Create an Account:</strong> Visit <strong>www.ketto.org</strong> and set up a <strong>fundraising</strong> account.
            <br>2. <strong>Create Your Campaign:</strong> Provide information about your cause, add visual elements like images or videos, and establish a <strong>fundraising</strong> target.
            <br>3. <strong>Promote Your Campaign:</strong> You can share your <strong>fundraising</strong> campaign on Facebook, Instagram, Whatsapp or any other social media platforms or through email so that you can reach more people.
            <br>4. <strong>Engage with Supporters:</strong> Update your supporters regularly and thank them for their contributions.
            `,
      children: null
    },
    {
      id: 7,
      heading: '',
      subHeading: 'Fundraising For NGOs',
      desc: `Ketto offers NGOs a platform to <strong>raise funds online</strong> for a variety of causes, such as education, animal welfare, and more. When it comes to <strong>crowdfunding in India</strong>, crowdfunding platforms help connect NGOs with a community of supporters who want to help them raise funds for their cause. When NGOs start a <strong>fundraiser</strong> on <strong>Ketto</strong>, they are able to share photos and their story with the community and raise <strong>funds online</strong> for a cause they feel passionately for.`,
      children: null
    },
    {
      id: 8,
      heading: '',
      subHeading: 'Memorial Crowdfunding',
      desc: `When a loved one passes away, their family, in many cases, struggles not just emotionally but financially as well, especially when they were dependent on the individual who passed away. By starting a memorial <strong>fundraiser</strong>, the deceased's family members can <strong>raise money online</strong> for rent, food, or medical bills until they are strong enough to fund themselves. Ketto provides a platform for friends and family to come together and help the bereaved family during this difficult time.`,
      children: null
    },
    {
      id: 9,
      heading: '',
      subHeading: 'Education Fundraising',
      desc: `At <strong>Ketto</strong>, we believe education is a fundamental right, and <strong>crowdfunding</strong> is a powerful way to make it accessible to those who cannot afford it but have big dreams. Our platform allows individuals, schools, and organisations to create <a target="_blank"href="https://www.ketto.org/cause/fundraising-for-education?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">education fundraisers</a> that gather support for scholarships, infrastructure improvements, and innovative educational programs. If every child is educated, then it will mean that our country will reach new heights since education is a tool to bring about a positive change in society.`,
      children: null
    },
    {
      id: 10,
      heading: '',
      subHeading: 'Medical Fundraising',
      desc: `We at <strong>Ketto</strong> understand that medical expenses can be overwhelming, and that's why we're dedicated to making healthcare accessible through crowdfunding. One can start a <a target="_blank"href="https://www.ketto.org/cause/medical-fundraising?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">medical fundraiser</a> for their own medical treatment or surgery or for someone else. With the support of our wonderful community of supporters and your loved ones, you can raise funds for the same and get the medical treatment you or someone else needs.`,
      children: null
    },
    {
      id: 11,
      heading: 'Benefits Of Crowdfunding On Ketto',
      subHeading: '',
      desc: `<p>Raising money through crowdfunding is a practical approach, and Ketto stands as a prime platform for both the campaigner and the donor. Let’s look at the amazing benefits you get if you start your <strong>fundraising</strong> journey with Ketto:</p>
      <ul>
        <li><strong>No Platform Fee:</strong> When Ketto completed its 8th anniversary, it expressed itself as India's first <strong>fundraising website</strong> to not charge a platform fee*, due to which the person starting the fundraiser, i.e. the campaigner is able to raise more funds.</li><br>
        
        <li><strong>Easy Payout:</strong> You can withdraw the amount of money collected by you for campaigning at any time that you feel the need.</li><br>
        
        <li><strong>Web App:</strong> Ketto's web app keeps you informed about your <strong>online fundraiser</strong> in real-time.</li><br>
        
        <li><strong>24/7 Support:</strong> <strong>Ketto customer care</strong> is always available to help you out or solve your queries. We can be reached through calls, WhatsApp, emails, and SMS, and our instant chat interface is also available anytime.</li><br>
        
        <li><strong>International Payment Support:</strong> Contributions in multiple currencies from people who contribute from outside India can be relatively easily received through Ketto, regardless of who your supporters are. This helps your <strong>Ketto fundraiser</strong> reach more people, thereby ensuring you're able to <strong>maximise</strong> the same and raise more funds.</li><br>
        
        <li><strong>Dedicated Fundraiser Expert:</strong> When you start a fundraiser on Ketto, we will assign a dedicated fundraising expert to your fundraiser. These experts are there to make your fundraising journey smooth and easy. They will help you with everything, from setting up your fundraiser to reaching your goal and any queries you have in between.</li><br>

        <li><strong>Advertising Support:</strong> At Ketto, we understand how important it is for you to reach a wider audience in order to make the most of your fundraising efforts. That is why we offer advertising support so that your fundraiser can reach as many people as possible. Ketto's team of experts strategically target these ads to reach those people who are most likely to contribute towards your fundraiser, ensuring you can <strong>raise money online</strong> faster and help your campaign reach its full potential.</li><br>

        <li><strong>Collaborative Fundraising:</strong> When you start a fundraiser on <strong>Ketto</strong>, <strong>crowdfunding</strong> won't just be about raising money; you will also be building a community. Anyone can help make your fundraiser a success; this includes your friends, family, colleagues, etc. They can do so by contributing to your fundraiser and also by sharing the link to your fundraiser with people they know. This will increase the reach of your fundraiser and reach your fundraising goal sooner. By involving more people and <strong>crowdfunding</strong>, <strong>Ketto</strong> can help you <strong>maximise</strong> your fundraising efforts and make your <strong>fundraiser</strong> a success.</li><br>

  <li><strong>Keep What You Raise:</strong> After we deduct our transaction fee, you keep all the money you raise. This can help cover any extra costs that come up even after your <strong>fundraiser online</strong> is over.</li><br>

  <li><strong>No Repayment Needed:</strong> When you take bank loans, you have to repay them back after a certain amount of time, and usually with interest. The 'interest' is the cost of borrowing the money. However, when it comes to crowdfunding, you don't have to pay back the money you raise on <strong>Ketto fundraising</strong>. This money can even help cover costs after your fundraiser ends.</li><br>

  <li><strong>Trust & Safety:</strong><br>
  At <strong>Ketto</strong>, we place a lot of value on transparency and honesty. We do our best to ensure that your fundraising journey is easy, smooth and secure.</li><br>
      </ul>
      `,
    },
    {
      id: 12,
      heading: '',
      subHeading: 'FAQs',
      desc: `<h4>Q. How could I be sure that my contribution will reach the person for whom the fundraiser has been started?</h4>
        <p>A. Once you have contributed towards a fundraiser, you will receive updates through Email/SMS/WhatsApp over the following months about the fundraiser that you supported. Alternatively, you can go to the fundraiser page and message the person who has started the fundraiser through the 'Contact Button' option.</p>

        <h4>Q. If I make a contribution towards a fundraiser, what steps does Ketto follow to protect my identity and payment information?</h4>
        <p>A. At Ketto, we give utmost importance to your safety. We understand how important your personal information is to you, and that is why we take every measure to protect it. Ketto uses high-security measures, including 128-bit encryption, to keep your information safe. We also never share your personal details without your permission.</p>

        <h4>Q. What do you mean by ‘Ketto Assured’?</h4>
        <p>A. Imagine you are scrolling through Ketto, and you come across a medical fundraiser you wish to contribute to. You will see an ‘<a target="_blank"href="https://www.ketto.org/blog/is-ketto-genuine?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">Assured Protection</a>’ batch on the same. This batch means that the Ketto team has personally verified that the fundraiser is authentic and credible and that your contribution reaches the patient who is undergoing treatment. The Ketto team thoroughly verifies the fundraisers on our platform to <a target="_blank"href="https://www.ketto.org/blog/are-campaigns-in-ketto-genuine?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">ensure they are real</a>. When you contribute to fundraisers on our platform, we will ensure that the amount you contribute reaches the person or beneficiaries the fundraiser was started for.</p>

        <h4>Q. How can I start a fundraiser on Ketto for non-medical reasons, like education or memorial?</h4>
        <p>A. Visit our site, <a target="_blank"href="https://www.ketto.org?utm_source=HomePage&utm_medium=HomePageFooter&utm_campaign=HP">www.ketto.org</a> and then click on the 'Start a Fundraiser' button. After doing this, since you don't want to start a medical fundraiser, change your purpose of starting the fundraiser from 'Medical Treatment' to 'Other Cause', and after this, you can submit the form.</p>`,
      children: null
    },
  ]
};
