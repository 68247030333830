export const HOSPITAL_LISTING_SEO = {
  metaTitle: 'Find Hospitals Near You | Best Hospitals in India | Ketto',
  metaDesc: 'Find hospitals near you and which are the best hospitals in India. You can search for the best multispecialty hospitals near your location with Ketto.org.',
  title: `Find Hospitals Near You | Best Hospitals in India | Ketto`,
  content: `<p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">For over a decade, Ketto has helped patients and families in need raise funds for urgent medical treatments and surgeries through crowdfunding.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Ketto has been working closely with over 200 of its partner hospitals that have a track record of medical excellence, and the impact has been monumental. Our partner hospitals include Medicover Hospital Hyderabad, Global Hospital Mumbai, Kauvery Hospital Bangalore, Bombay Hospital Mumbai, Global Hospital Chennai, Rainbow Children&#39;s Hospital Hyderabad, Kauvery Hospital Chennai, Artemis Hospital Gurgaon, Apollo Hospital Kolkata, AIIMS Hospital Delhi, Tata Memorial Hospital Mumbai, Medanta Hospital Patna and SMS Hospital Jaipur, to name a few.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Doctors and healthcare professionals at Ketto&#39;s partner hospitals are the reason why countless patients have recovered. These talented individuals have spent years learning and training to become experts in their fields. But what sets them apart is not just their medical knowledge &ndash; it&#39;s also their caring and understanding nature. They treat patients with kindness and empathy, which can really change a patient&#39;s experience.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Moreover, they have access to top-notch facilities in these hospitals &ndash; this means that patients receive the very best care possible. It&#39;s all about ensuring every patient gets the treatment and attention they deserve.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">This partnership fosters a seamless connection between those in need and Ketto&#39;s donors, who believe in their journey toward recovery. Together, we ensure that all funds raised are channelled effectively and efficiently.</span></span></span></p><p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">As we continue our mission, Ketto is committed to maintaining a strong connection between patients, donors, and partner hospitals. With each success story, we show our dedication to a world where kindness knows no bounds. A little support, especially when someone is going through a challenging time, can make their journey a little less difficult. Together, we stand united in the journey of continuing to make healthcare accessible and affordable to all and making a difference in the world.</span></span></span></p>
  <h2><span style="font-size:16pt"><span style="font-family:Arial,sans-serif"><span style="color:#000000"><span style="background-color:#ffffff">How can you Connect with Ketto Managers at the Hospital?</span></span></span></span></h2><p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Calling:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> This is the most common way to connect with a Ketto manager. You can call the hospital&#39;s general number and ask to speak to them. The hospital will usually have a dedicated Ketto manager who is responsible for coordinating crowdfunding campaigns for patients.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Hospital enquiry:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> You can get in touch with a Ketto manager by enquiring with the hospital authority directly. The respective personnel will provide you with information regarding the Ketto manager.</span></span></span></p>  
  <p><span style="font-size:16pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Why have we partnered with the best hospitals in India?</span></span></span></p> 
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Partnering with some of the best hospitals in India amplifies the impact of Ketto&#39;s mission and enhances the effectiveness of its crowdfunding efforts. Here are some reasons why Ketto has chosen to partner with these esteemed hospitals:</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Medical Excellence and Expertise:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Ketto&#39;s partner hospitals have established themselves as leaders in the field of healthcare due to their exceptional medical expertise, advanced technologies, and experienced medical professionals. These hospitals are known to deliver high-quality medical care and successful treatments across various medical specialities.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>State-of-the-Art Facilities:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Partnering with the best hospitals in India ensures that patients who require medical assistance receive treatment in facilities equipped with the latest medical technologies, equipment, and infrastructure. This translates to better outcomes and improved patient experiences.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Comprehensive Care</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">: The partnered hospitals offer a wide range of medical services, from routine medical procedures to complex surgeries and specialised treatments. This comprehensive approach to healthcare ensures that patients receive all-encompassing medical care under one roof.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Specialised Treatment:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Many of the partner hospitals are known for their specialisation in specific medical areas such as cancer treatment, organ transplantation, cardiac care, paediatrics, and more. This specialisation enhances the success rates of treatments and surgeries.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Credibility and Trust:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Partnering with the best hospitals in India adds a layer of credibility and trust to Ketto&#39;s crowdfunding efforts. Donors are more likely to contribute when they know their funds are directed towards treatments at established and respected medical institutions.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Increased Reach:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Collaborating with the best hospitals in India allows patients or their kin who have started fundraisers on Ketto to reach a wider audience, as donors are more likely to trust reputable hospitals. This leads to patients receiving timely support from Ketto&#39;s kind-hearted donor community.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Synergistic Values:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Ketto&#39;s commitment to making healthcare accessible aligns with the partner hospitals&#39; own missions of offering quality medical care to all their patients, regardless of their financial background. This shared vision drives positive change.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Efficient Fund Utilisation:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> Ketto&#39;s partner hospitals are equipped to efficiently utilise the funds raised through Ketto&#39;s platform. The funds are directly channelled to cover medical expenses, ensuring that each donation directly contributes to the patient&#39;s treatment journey.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Long-Term Impact:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> By collaborating with the best hospitals in India, Ketto is not only facilitating immediate treatments but also contributing to the long-term improvement of healthcare infrastructure and accessibility in India.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"><strong>Collective Impact:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a"> The partnership showcases the collective power of individuals, hospitals, and a crowdfunding platform working together for a noble cause. It highlights how a united effort can positively impact patients as well as their families.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">In summary, partnering with the best hospitals in India aligns perfectly with Ketto&#39;s mission of making healthcare accessible and affordable to all. These partnerships bolster credibility, enhance the quality of care provided to patients, and amplify the positive impact of the crowdfunding platform.</span></span></span></p>
  <h2><span style="font-size:16pt"><span style="font-family:Arial,sans-serif"><span style="color:#000000">Ketto&rsquo;s Impact</span></span></span></h2>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Ketto&#39;s unwavering commitment to its mission of &#39;Healthcare for all&#39;, along with its strategic partnership with esteemed hospitals like these, not only exemplifies a powerful force for good but also showcases the remarkable potential of collective action in making a substantial, positive difference in the realm of healthcare.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Together with over 200 of its partner hospitals, Ketto has saved countless lives, conquered various challenges and rekindled hope. So far, Ketto has helped raise over Rs 1000 crore and patients, young and old, have been able to undergo life-saving treatments and surgeries for complications related to premature birth, cancer, liver disease, kidney disease, heart defects, blood disorders, accidental injuries, Spinal Muscular Atrophy and many other ailments.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Through their kindness, Ketto&#39;s community of over 55 lakh donors has proven that collective contributions can lead to remarkable outcomes. Besides crowdfunding, Ketto also provides patients or their families with avenues to seek loans and obtain insurance coverage.</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">The partnership between Ketto and its partner hospitals stands as a powerful testament to the power of collective action, empathy, and innovation, creating a legacy of impact that resonates far beyond the confines of medical facilities.&nbsp;</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">As we continue our mission, Ketto is committed to maintaining a strong connection between patients, donors, and partner hospitals. Each success story shows our dedication to a world where kindness knows no bounds. A little support, especially when someone is going through a challenging time, can make their journey a little less difficult.&nbsp;</span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Arial,sans-serif"><span style="color:#0e101a">Together, we stand united in the journey of continuing to make healthcare accessible and affordable to all and making a difference in the world &ndash; this will mean more families helped, stronger communities, and more smiles spreading far and wide.</span></span></span></p>`
}