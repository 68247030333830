import { Component, HostListener, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  private delete = new BehaviorSubject<boolean>(false);
  public deleteObs = this.delete.asObservable();

  isLoading;
  @ViewChild('deleteTemplate', { static: true }) deleteTemplate: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  @HostListener('keydown.esc')
  public onEsc() {
  }

  ngOnInit(): void {
    if (this.data.type === 'confirmDelete') {
      this.data.content = this.deleteTemplate;
    }
  }

  deleteComment(): void {
    this.delete.next(true);
  }

  cancelDelete(): void {
  }

}
