import { VwoService } from './../../../../shared/services/vwo/vwo.service';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { EventsService, StorageService, UtilService, VariablesService } from '../../../../shared/services';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-abandoned-cart',
  templateUrl: './abandoned-cart.component.html',
  styleUrls: ['./abandoned-cart.component.scss']
})
export class AbandonedCartComponent implements OnInit, OnChanges {

  showAbandonedCart;
  abandonedCart;
  @Input() amount;
  @Input() config;
  params;

  constructor(
    public vars: VariablesService,
    private utilService: UtilService,
    private storageService: StorageService,
    private eventsService: EventsService,
    public vwo: VwoService,
    public actRout: ActivatedRoute
  ) { }

  ngOnInit() {
    if (typeof window !== 'undefined') {
      this.abandonedCart = this.storageService.get('abandonedCart');
    }
  }
  ngOnChanges() {
    if (this.config && !this.config.hideAbandonedCart) {
      this.abandonedCart = this.storageService.get('abandonedCart');
      this.showAbandonedCart = this.abandonedCart ? this.abandonedCart.showAbandonedCart : false;
    }
  }
  navigate() {
    const systemEvent = {
      eventName: 'sip_abandoned',
      page_name: this.vars.pageName,
      info_3: 'impact'
    };
    this.eventsService.sendSystemEvent(systemEvent).subscribe(_ => _);
    delete this.abandonedCart.showAbandonedCart;
    this.utilService.router.navigate(['/new/sip/payment-standalone'],
      {
        queryParams: this.abandonedCart
      }
    );
  }

  close() {
    this.eventsService.sendSystemEvent({
      eventName: 'cartpopup_closed',
      page_name: this.vars.pageName,
      info_3: 'impact'
    }).subscribe(_ => _);
    this.showAbandonedCart = false;
    this.storageService.delete('abandonedCart');
  }
}
