export const AppLinks = {
  android: 'https://play.google.com/store/apps/details?id=org.ketto.sip',
  android_mcd: 'https://play.google.com/store/apps/details?id=org.ketto.mcd',
  iOS: 'https://apps.apple.com/sr/app/ketto-sip/id1582755806',
  iOS_mcd: 'https://apps.apple.com/sr/app/ketto-for-fundraiser/id1602237545',
  app_flyer: 'https://kettosip.onelink.me/95wu/q1hg3zku',
  app_flyer_android: 'https://kettosip.onelink.me/95wu/jgrg5im3'
};

export const MedibuddyAppLinks = {
  android: 'https://rb.gy/i5er2',
  ios: 'https://rb.gy/jeu7v6'
};