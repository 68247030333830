import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LazyModuleLoaderModel } from './directive/lazy.model';

@Component({
  selector: 'app-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.scss']
})
export class ModuleLoaderComponent implements OnInit, OnChanges {

  @Input() load;
  @Input() moduleId;
  @Input() selector;
  @Input() inputs;
  @Input() outputs;

  lazyModuleConfig: LazyModuleLoaderModel;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inputs?.currentValue) {
      this.loadLazyModule();
      return;
    } else if ((!changes?.load?.currentValue) || (changes?.load?.currentValue?.toString() === changes?.load?.previousValue?.toString()) || (changes?.load?.currentValue === changes?.load?.previousValue)) {
      return;
    }
    this.loadLazyModule();
  }


  loadLazyModule() {
    if (!this.load) {
      return;
    }
    this.lazyModuleConfig = {
      loader: this.getModulePath(),
    }
  }

  getModulePath() {
    switch (true) {
      case this.moduleId === 'search-bar':
        return () => import('./../search-bar/search-bar.module');

      case this.moduleId === 'login-popup':
        return () => import('./../login-popup/login-popup.module');

      case this.moduleId === 'update-tab':
        return () => import('./../update/update.module');

      case this.moduleId === 'comment-card':
        return () => import('./../comment-card/comment-card.module');

      case this.moduleId === 'talktous-popup':
        return () => import('./../common-talktous-modal/common-talktous-modal.module');

      case this.moduleId === 'otp-confirmation':
        return () => import('./../otp-confirmation/otp-confirmation.module');

      case this.moduleId === 'referral-page-v2':
        return () => import('./../../referral-page-v2/referral-page-v2.module');

      case this.moduleId === 'fundraiser-thankyou':
        return () => import('./../fundraiser-thankyou/fundraiser-thankyou.module');

      case this.moduleId === 'impact-signup-card-v4':
        return () => import('./../impact-signup-card-v4/impact-signup-card-v4.module');

      case this.moduleId === 'impact-signup-card-v5':
        return () => import('./../impact-signup-card-v5/impact-signup-card-v5.module');

      case this.moduleId === 'impact-signup-card-v6':
        return () => import('./../impact-signup-card-v6/impact-signup-card-v6.module');

      case this.moduleId === 'impact-signup-card-v7':
        return () => import('./../impact-signup-card-v7/impact-signup-card-v7.module');

      case this.moduleId === 'sip-goal-v2':
        return () => import('./../sip-goal-v2/sip-goal-v2.module');

      case this.moduleId === 'pledge-more-typ':
        return () => import('./../pledge-more-typ/pledge-more-typ.module');

      case this.moduleId === 'thankyou-page-standard':
        return () => import('./../thankyou-page-standard/thankyou-page-standard.module');

      case this.moduleId === 'impact-thankyou':
        return () => import('./../impact-thankyou/impact-thankyou.module');

      case this.moduleId === 'sip-quiz-v2':
        return () => import('./../sip-quiz-v2/sip-quiz-v2.module');

      case this.moduleId === 'sip-thankyou-first':
        return () => import('./../sip-thankyou-first/sip-thankyou-first.module');

      case this.moduleId === 'sip-thankyou':
        return () => import('./../../onboard-sip/onboard-sip.module');

      case this.moduleId === 'payment-module':
        return () => import('./../payment/payment.module');

      case this.moduleId === 'sip-thankyou-v1':
        return () => import('./../onboarding-sip-v1/onboarding-sip-v1.module');

      case this.moduleId === 'sip-thankyou-v2':
        return () => import('./../onboarding-v2/onboarding-v2.module');

      case this.moduleId === 'download-app-banner':
        return () => import('./../download-app-banner/download-app-banner.module');

      case this.moduleId === 'typ-revamp':
        return () => import('./../typ-revamp/typ-revamp.module');

      case this.moduleId === 'thankyou-loading-header':
        return () => import('./../thankyou-loading-header/thankyou-loading-header.module');

      case this.moduleId === 'multi-cause-sip-selection':
        return () => import('./../multi-cause-sip-selection/multi-cause-sip-selection.module');

      case this.moduleId === 'select-live':
        return () => import('./../select-live/select-live.module');

      case this.moduleId === 'thankyou-page-tip':
        return () => import('./../thankyou-page-tip/thankyou-page-tip.module');

      case this.moduleId === 'thankyou-page-aftertip':
        return () => import('./../thankyou-page-aftertip/thankyou-page-aftertip.module');

      case this.moduleId === 'portfolio-agent-nudge':
        return () => import('../portfolio-agent-nudge/portfolio-agent-nudge.module');

      case this.moduleId === 'order-reco-card':
        return () => import('../order-reco-card/order-reco-card.module');

      case this.moduleId === 'captcha-input':
        return () => import('../captcha-input/captcha-input.module');

      case this.moduleId === 'intl-input':
        return () => import('../intl-input/intl-input.module');

      case this.moduleId === 'sip-info':
        return () => import('../sip-info/sip-info.module');

      case this.moduleId === 'sip-pitch-card-v4':
        return () => import('../sip-pitch-card-v4/sip-pitch-card-v4.module');

      case this.moduleId === 'ministory_quiz':
        return () => import('../ministory-quiz/ministory-quiz.module');

      case this.moduleId === 'health-first-typ':
        return () => import('../health-first-typ/health-first-typ.module');

      case this.moduleId === 'leave-confirmation-dm':
        return () => import('../leave-confirmation-dm/leave-confirmation-dm.module');

      case this.moduleId === 'common-header':
        return () => import('../common-header/common-header.module');

      case this.moduleId === 'footer':
        return () => import('../footer/footer.module');

      case this.moduleId === 'sip-story-banner':
        return () => import('../sip-story-banner/sip-story-banner.module');

      case this.moduleId === 'sip-story-pitch-card':
        return () => import('../sip-story-pitch-card/sip-story-pitch-card.module');

      case this.moduleId === 'multi-cause-dm':
        return () => import('../multi-cause-dm/multi-cause-dm.module');

      case this.moduleId === 'sip-pitch-banner-v2':
        return () => import('../sip-pitch-banner-v2/sip-pitch-banner-v2.module');

      case this.moduleId === 'video-dm':
        return () => import('../video-dm/video-dm.module');

      case this.moduleId === 'non-login-typ':
        return () => import('../non-login-typ/non-login-typ.module');

      case this.moduleId === 'welcome-back-nudge':
        return () => import('../welcome-back-nudge/welcome-back-nudge.module');

      case this.moduleId === 'type-delete':
        return () => import('../type-delete/type-delete.module');
  
      default:
        break;
    }
  }
}
