import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { HOSPITALS } from 'src/app/hospitals/objects/hospitals';
import { IHospital } from 'src/app/hospitals/objects/hospital.model';
import { HOSPITAL_LISTING_SEO } from 'src/app/hospitals/objects/hospital-listing-seo';
import { UtilService } from 'src/app/shared/services';

@Component({
  selector: 'app-hospital-footer-seo',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
  ],
  templateUrl: './hospital-footer-seo.component.html',
  styleUrls: ['./hospital-footer-seo.component.scss']
})
export class HospitalFooterSeoComponent {
  constructor(
    private router: Router,
    public util: UtilService
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setTag();
          this.switchFooterSeo();
        }
      });
  }
  footerSeoContent;
  tag;
  setTag() {
    this.tag = this.router.url.split('/')[this.router.url.split('/').length - 1];
  }

  switchFooterSeo() {
    const hospital: IHospital = HOSPITALS.find(o => o.tag === this.tag);
    if (this.router.url === '/hospitals') {
      this.footerSeoContent = HOSPITAL_LISTING_SEO;
    } else {
      this.footerSeoContent = hospital.footer;
    }
  }
  ngAfterViewInit() {
    this.setTag();
    this.switchFooterSeo();
  }

  scrollDown() {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight - (document.getElementById('footer-seo').clientHeight + 100),
          behavior: 'smooth'
        });
      }, 200);
    }
  }
}