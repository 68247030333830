import { AuthService } from './../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService, CookieService, UtilService, VariablesService } from './../../services/index';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private variablesService: VariablesService,
    private actRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.sessionLogout().subscribe((res) => {
      this.logoutUser();
    },
      (err) => {
        this.logoutUser();
      }
    );
  }

  logoutUser() {
    this.storageService.delete('user');
    this.storageService.delete('userdata');
    this.storageService.deleteFromSession('showBanner');
    this.cookieService.deleteCookie('u_auth', '.ketto.org');
    this.utilService.isToken = false;
    this.utilService.isLoggedIn.next(false);
    this.variablesService.userData.next(null);
    this.afterLogoutRedirect();
  }

  afterLogoutRedirect() {
    const REDIRECT_URI = this.actRoute.snapshot.queryParams['redirect_uri'];
    if (REDIRECT_URI && this.authService.verifyURI(REDIRECT_URI)) {
      this.utilService.nativeNavigation(REDIRECT_URI);
    } else {
      this.utilService.navigate('/');
    }
  }

}
