import { VariablesService } from './../../services/variables/variables.service';
import { StorageService } from './../../services/storage/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilService } from '../../services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class SacGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    public variablesService: VariablesService,
    private util: UtilService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(route);
  }

  checkLogin(route): boolean {
    const data = this.util.getUser();
    if (data) {
      switch (true) {
        case data.user?.isLoggedIn:
        case this.variablesService.isFOS:
          return true;
        default:
          this.router.navigate(['new/crowdfunding'], { queryParamsHandling: 'merge', queryParams: route.queryParams });
          return false;
      }
    } else {
      this.router.navigate(['new/crowdfunding'], { queryParamsHandling: 'merge', queryParams: route.queryParams });
      return false;
    }
  }

}
