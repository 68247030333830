export const FOOTER_SEO_CONTENT_TRANSPLANT = {
  mainTitle: 'Start your Fundraiser for Your Transplant Surgery with Ketto',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'Raise Funds for Transplants',
      desc: `<p class="desc">An increasing number of patients and families utilize online crowdfunding to support their medical expenses related to organ transplantation. </p>
            <p class="desc">Ketto's Online crowdfunding platform is widely used to raise funds for health-related expenses associated with medical conditions like organ transplants. Crowdfunding reduces the financial burden families, and individuals experience when undergoing these medical complications.</p>
            <p class="desc">At Ketto, we know that social media and online connectivity have welcomed compassion, allowing individuals, NGOs, and families to request funds beyond their geographical limitations. Medical crowdfunding connects individuals and fulfills their healthcare needs through fundraising. </p>
            <p class="desc">A majority of campaigns on <a href="https://www.ketto.org/">Ketto</a> are initiated on behalf of the patients that are very sick and dependent on others. </p>`,
      children: null
    },
    {
      id: 2,
      heading: 'Transplant Crowdfunding on Ketto is an efficient way to raise money for medical bills online.',
      subHeading: '',
      desc: `<p class="desc">When the one you love needs treatment, medical expenses are an added stress. This, along with treatments, can continue for a long time causing medical bills and medical expenses to keep piling up for the family.</p>`,
      children: null
    },
    {
      id: 3,
      heading: 'Here\'s what you can do!',
      subHeading: '',
      desc: ``,
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'Try crowdfunding on Ketto!',
      desc: `<p class="desc">Crowdfunding is the best way to raise the finances needed by starting a transplant campaign online and share it with family, friends, and social networks.</p>
            <p class="desc">We provide you all the support you need to raise funds for transplant-related medical costs and helps you pay expensive medical bills.</p>`,
      children: null
    },
    {
      id: 5,
      heading: 'Here are some fundraising ideas to cover medical expenses',
      subHeading: 'You can help a family member or a close friend',
      desc: `<a href="https://www.ketto.org/new/crowdfunding">Start a fundraiser on Ketto</a> to help a family member, friend, or colleague in need of a large number of funds for organ transplant and other medical treatment costs.`,
      children: null
    },
    {
      id: 6,
      heading: '',
      subHeading: 'You can Fund your own treatment',
      desc: 'On Ketto, you can start a transplant fundraiser to support your own organ transplant-related medical expenses and independently ensure you take care of your own medical treatment without the worry of debt of a bank loan.',
      children: null
    },
    {
      id: 7,
      heading: '',
      subHeading: 'Be Combat Ready for any Medical Emergency',
      desc: `Be it accidents, heart attacks, or any health-related emergency. With Ketto's online transplant funding, be ready by raising funds quickly for any medical emergency.`,
      children: null
    },
    {
      id: 8,
      heading: '',
      subHeading: 'Support patients',
      desc: 'Every doctor tries to ensure their patients receive the best treatment. However, numerous patients cannot bear the expenses of modern-day surgeries and organ transplants. As a doctor, you can ensure your patient receives the required funds for their medical treatment by starting a fundraiser for them on Ketto.',
      children: null
    },
    {
      id: 9,
      heading: '',
      subHeading: 'Funds for at-home care',
      desc: 'Countless patients and senior citizens require medical care administered in the comfort of their homes. This can be ensured only when they receive the funds to pay money today for nurses and at-home doctor visits. By raising funds with Ketto, you can ensure they never run out of funds.',
      children: null
    },
    {
      id: 10,
      heading: 'Raise funds for Organ Transplantation',
      subHeading: '',
      desc: 'You can fund organ transplantation costs by raising money on Ketto\'s online crowd fundraising platform.',
      children: null
    },
    {
      id: 11,
      heading: '',
      subHeading: 'How can I raise money for my medical bills on Ketto?',
      desc: '',
      children: [
        {
          id: 1,
          heading: '',
          desc: '',
          bullet: false,
          list: [
            {
              id: 1,
              title: 'Visit Ketto.org',
              desc: '',
            },
            {
              id: 1,
              title: 'Click Start a fundraiser',
              desc: '',
            },
            {
              id: 1,
              title: 'Share Your Fundraiser',
              desc: '',
            },
            {
              id: 1,
              title: 'Receive Money For Transplant Bills',
              desc: '',
            },
          ]
        }
      ]
    },
    {
      id: 12,
      heading: '',
      subHeading: 'Here\'s Why You Should Raise Funds with Ketto!',
      desc: '',
      children: [
        {
          id: 1,
          heading: '',
          desc: '',
          bullet: false,
          list: [
            {
              id: 1,
              title: 'Start a fundraiser in a couple of minutes',
              desc: ''
            },
            {
              id: 2,
              title: 'Ketto Crowdfunding for an organ transplant is the best alternative to health insurance or bank loans',
              desc: ''
            },
            {
              id: 3,
              title: 'Funds are raised quickly, and the amount is disbursed swiftly',
              desc: ''
            },
            {
              id: 4,
              title: '0% Platform fees*',
              desc: ''
            },
            {
              id: 5,
              title: '24x7 fundraising support on email & chat',
              desc: ''
            }
          ]
        }
      ]
    },
    {
      id: 13,
      heading: '',
      subHeading: '',
      desc: 'Visit Ketto.org to know more about raising funds for an organ transplant. Or visit our <a href="https://www.ketto.org/blog">Blog</a> for more content!'
    }
  ],
  searches: null
};
