export const FOOTER_SEO_CONTENT_HOW_IT_WORKS = {
  mainTitle: 'What Is Crowdfunding and How Does It Work?',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'What is Crowdfunding?',
      desc: 'Crowdfunding is a way to raise money from a large number of people. Large groups of people pool together small investments to fulfil the firm\'s capital needs and help it get off the ground. Businesses and charities can create a campaign for specific causes, and anyone can contribute.',
      children: [
        {
          id: 1,
          heading: 'Types of Crowdfunding',
          desc: 'Crowdfunding can be categorised into four types:',
          list: [
            {
              id: 1,
              title: 'Donation-based crowdfunding',
              desc: 'Donation-based crowdfunding is where people donate funds to support a cause or campaign. Here, people are looking for nothing in return. Donations are one of the ways charitable trusts and NGOs earn money.'
            },
            {
              id: 2,
              title: 'Debt-based crowdfunding',
              desc: 'Under this type of crowdfunding, the investors lend a minimal amount of loan to the borrower who will have to repay the amount with interest. Debt-based donations are also referred to as peer-to-peer (P2P) lending.'
            },
            {
              id: 3,
              title: 'Rewards-based crowdfunding',
              desc: 'Under this type of crowdfunding, the investors receive a donation in return. The benefits vary based on the amount of the gift, which promotes higher contributions. Based on how much money the participants donate to the initiative, they will earn a token like a t-shirt, product, or service – sometimes at discounted prices.'
            },
            {
              id: 4,
              title: 'Equity-based crowdfunding',
              desc: 'While some crowdfunding campaigns do not encourage supporters to own a portion of the organisation they sponsor, equity-based crowdfunding enables small companies and start-ups to give up a part of their business in exchange for financing. These contributions are a form of a grant in which members earn business shares based on how much money they donate. <br><br> To know more about the types of crowdfunding in a detailed way, do <a href="https://www.ketto.org/blog/what-are-the-types-of-crowdfunding-in-india"> click here</a> <br><br> *According to the laws in India, crowdfunding based on equity is illegal, though the RBI controls peer-to-peer lending.'
            }
          ]

        },
        {
          id: 2,
          heading: 'How Crowdfunding Works?',
          desc: 'If you think crowdfunding is a recent concept that is slowly taking over, it is not. It was practised in ancient India, primarily to finance cultural and religious causes. Back then, it was called "Chanda." In the present day, crowdfunding is successfully running in India and is one of the most suitable fundraising options for any cause. Ketto’s website is available for people to kickstart their fundraising campaigns. <br> Ketto’s Crowdfunding website will mention your project/cause and assist you raise funds for it. All you have to do is build a campaign, set a target, and encourage it.',
          list: null
        },
        {
          id: 3,
          heading: 'Laws of Crowdfunding In India:',
          desc: 'This is the most famous question we\'ve heard today-"Is crowdfunding still legal in India?" <br> Yes, aside from crowdfunding based on equity, other forms are entirely legitimate. Crowdfunding in India is regulated by the Securities and Exchange Board of India (SEBI). It is the regulator of markets that oversees the stock market in particular. SEBI was founded as a non-legislative body in 1988. In 1992, the Parliament passed the SEBI Act, which gave SEBI legislative powers. There are some rules set out by SEBI to regulate crowdfunding.',
          list: null
        },
        {
          id: 4,
          heading: 'Benefits of Crowdfunding',
          desc: 'Below are a few of the many possible advantages of crowdfunding:',
          list: [
            {
              id: 1,
              title: 'Reach',
              desc: 'By using a crowdfunding platform, you can access thousands of accredited investors, who have the potential to invest in your fundraising campaign.'
            },
            {
              id: 2,
              title: 'Presentation',
              desc: 'Creating a campaign allows you to dive deeper into your business or idea and make it look more polished in front of the investors.'
            },
            {
              id: 3,
              title: 'PR and Marketing',
              desc: 'You can promote your campaign on various social media channels or via email newsletters and other online marketing techniques. Your fundraising campaign can help you steer some organic traffic right on your website.'
            },
            {
              id: 4,
              title: 'Product Validation',
              desc: 'Introducing your business concept to the masses offers an ideal opportunity to test and optimise your proposal. When new buyers continue to show concern and pose concerns, you can soon see what is lacking in the product/service to make them more likely to buy in.'
            },
            {
              id: 5,
              title: 'Performance',
              desc: `One of the significant aspects of online crowdfunding is the potential to streamline the fundraising project by developing a single, detailed profile digitally that helps you embed all your opportunities and prospective investors.
                            Instead of multiplying efforts by printing notes, compiling binders, and manually editing each version, you can view all the details digitally in a much more convenient way, giving you more time to manage your company rather than fundraising.`
            }
          ]
        },
        {
          id: 5,
          heading: '',
          desc: `With so many <a href="https://www.ketto.org/blog/five-quick-fundraising-ideas">crowdfunding ideas</a> being implemented and embraced by people, all you need to do is take a cue and go ahead with what works the best for you.
                    While some of these need a bit of planning and effort to put everything together, Medical crowdfunding, Cancer Crowdfunding, <a href="https://www.ketto.org/blog/what-is-sports-crowdfunding"> Sports Crowdfunding </a>, Education Crowdfunding, <a href="https://www.ketto.org/cause/animal-fundraising"> Animal Crowdfunding</a>
                    and Memorial Crowdfunding are the most popular, because they require minimum effort.`,
          list: null
        },
        {
          id: 6,
          heading: '',
          desc: ` All you need to do is go to <a href="https://www.ketto.org/">Ketto’s crowdfunding platform</a> and create your fundraiser. So, what are you waiting for? Have you created your fundraiser yet?
                    Create one and share the link with your friends and family. You will see how quickly your idea goes from simply an idea to an actual outcome, making a difference where it matters the most!`,
          list: null
        }
      ]
    },
  ]
};
