import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ICLientData, IUser } from './shared/models';
import {
  AuthService, EventsService, PortfolioService, ScriptLoaderService, SeoService, UtilService, VariablesService
} from './shared/services';
import { DefaultIPLocation } from './shared/models/default-ip';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loadingRouteConfig: boolean;
  public result;
  deferredPrompt: any;
  showButton = true;
  params;

  constructor(
    public router: Router,
    public actRoute: ActivatedRoute,
    public titleService: Title,
    public authService: AuthService,
    public util: UtilService,
    public vars: VariablesService,
    public scriptLoader: ScriptLoaderService,
    public seoService: SeoService,
    private events: EventsService,
    private portfolioService: PortfolioService
  ) { }

  ngOnInit(): void {
    this.util.setUtmCookie();
    this.params = this.util.getUrlParams();
    this.moduleLoader();
    this.seoService.createCanonicalURL();
    const webPageSchema = {
      '@context': 'http://schema.org',
      '@id': 'https://www.ketto.org',
      '@type': 'WebPage',
      'url': 'https://www.ketto.org',
      'name': 'Ketto - Crowdfunding India'
    };
    this.seoService.schemaOrgObject(webPageSchema);
    if (this.util.isBrowser) {
      this.setDeviceType();
      this.routeChangeListner();
      this.getClientIP();
      this.setUser();
    }
    this.setTitle();
    this.vars.listenToGlabalJsVar();
  }

  setDeviceType() {
    this.vars.deviceType = this.util.isMobile() ? 'mobile' : 'desktop';
    const platform = this.util.cookie.getCookie('platform');
    if (platform) {
      this.vars.deviceType = platform;
    }
    if (this.params?.platform) {
      this.util.storage.addSessionData('platform', this.params?.platform);
    }
    const sesPlatform = this.util.storage.getFromSession('platform');
    if (sesPlatform) {
      this.vars.deviceType = sesPlatform;
    }
  }

  /**Onlaod function */
  @HostListener('window:load', [])
  onWindowLoads() {
    if (this.util.isBrowser) {
      this.scriptLoader.loadScript('gtm', '', true).then();
      this.scriptLoader.loadScript('clevertap', '', true);
      this.scriptLoader.loadScript('fb_pixel_script', '', true);
      this.scriptLoader.loadScript('microsoft_clarity', '', true);
      // this.scriptLoader.load('google_optimize').then(() => {
      //   const _ct = new CustomEvent('google_optimize', { bubbles: true });
      //   document.dispatchEvent(_ct);
      // });
      if (!this.router.url.match('stories|fundraiser|thankyou|pledge-more|sip|portfolio|payment')) {
        this.events.pushNotificationCL();
      }
      this.vars.userData.subscribe(res => {
        if (res?.id) {
          this.events.clarityEventsPush(res?.id);
          if (res?.isDummyEmail) {
            this.vars.isDummyEmail = true;
          } else {
            this.vars.isDummyEmail = false;
          }
        }
      })
    }
  }

  routeChangeListner() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.actRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => {
      if (this.router.url.split('?')[0] !== this.vars.previousPageURL.split('?')[0]) {
        this.vars.previousPageURL = this.router.url;
        this.vars.pageName = event['page_name'] || '';
        this.util.setUtm();
        this.events.fbPixelPush('PageView', {});
        this.gtmOnLoad(event);
        if (!event.lastOrderSkip) {
          this.checkForLastOrder();
        }
      }
    });
  }

  /**Set user logged in flags */
  setUser() {
    try {
      const user = this.util.getUser()?.user;
      if (user && !this.vars.isVariableLogin) {
        if (user.isLoggedIn) {
          this.util.isLoggedIn.next(user.isLoggedIn);
        }
        this.vars.userData.next(this.util.getUserData());
        const domain = environment.name === 'local' ? 'localhost' : '.ketto.org';
        this.util.cookie.setCookie('is_logged_in', 'true', 365, domain);
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**Lazy loader module loader */
  moduleLoader() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }
  /**
   * Set page title on the basis of which
   * route is currently active
   */
  setTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.util.url.next(event);
      try {
        this.seoService.createCanonicalURL();
      } catch (e) {
        console.log(e);
      }
    });
  }
  /**Get the IP of the client */
  getClientIP() {
    const ip = this.util.storage.getFromSession('iplocation');
    if (!ip) {
      this.authService.getClientIP().subscribe((res: any) => {
        this.setClientIP(res.data);
      }, () => this.setClientIP(DefaultIPLocation));
    } else {
      this.setClientIP(ip);
    }
  }

  setClientIP(ip: ICLientData) {
    const criteoPartnerIDs = {
      'IN': 56509,
      'AE': 69550
    };
    this.vars.clientLocationData.next(ip);
    const criteoId = criteoPartnerIDs[ip.country_name] || 66025;
    this.events.gtmPush({ 'CriteoPartnerID': criteoId });
    this.util.storage.checkFromSession('iplocation', ip);
    this.setCurrency(ip);
  }

  setCurrency(ip: ICLientData) {
    const currencyFromUrl = this.actRoute.snapshot.queryParams.selected_currency;
    const currencyFromCode = this.util.getCurrencyFromCode(ip.country_code);
    const currencyFromSession = this.util.storage.getFromSession('currency');
    if (currencyFromUrl) {
      this.util.setCurrency(currencyFromUrl);
    } else if (currencyFromSession) {
      this.util.setCurrency(currencyFromSession);
    } else if (currencyFromCode) {
      this.util.setCurrency(currencyFromCode.currency);
    }
  }

  gtmOnLoad(event) {
    const user: IUser = this.util.getUserData();
    const currency = this.util.storage.getFromSession('currency');
    const gtmPush = {
      event: 'virtual_pageview',
      page_version: 'A',
      login_status: user ? 'logged in' : 'not logged in', // Populate either LOGGED IN or NOT LOGGED IN
      user_type: user ? user.entity_type : 'visitor',
      currency: currency, // Currency used
      // session_id: '',
      user_id: user ? user.id : '',   // Identifies unique users across multiple devices
      page_category: event.category,  // ex: home, crowdfunding, fundraiser etc
      page_name: event.page_name,  // name of the page
      sip: event.sip || false  // name of the page
    };
    this.vars.gtmPageData = gtmPush;
    this.events.gtmPush(gtmPush);
  }

  /**Get the total orders made by the user */
  checkForLastOrder() {
    this.vars.userData.subscribe(res => {
      if (res && !this.vars.lastOrderInfo.value) {
        this.getLastOrder();
      }
    });
  }
  getLastOrder() {
    const qparam = `?limit=1&orderBy=creation_date&sortedBy=desc`;
    this.portfolioService.getBeneficiaries(qparam).subscribe((res: any) => {
      if (res && res.data && res.data.data && res.data.data.length) {
        this.vars.setLastOrderValues(res.data.data[0]);
      }
    });
  }

}
