import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

  @Input() public type;
  @Input() public color;
  @Input() public label;
  @Input() public iconColor;
  @Input() public iconName;
  @Input() public icon;
  @Input() public suffixIcon?;
  @Input() public btnClass;
  @Input() public isLoading;
  @Input() public disabled;
  @Input() public externalIcon = false;
  @Output() btnClick = new EventEmitter();
  constructor() { }

  onBtnClick(event: any) {
    this.btnClick.emit(event);
  }

}