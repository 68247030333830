import { forkJoin } from 'rxjs';
import { UtilService } from '../util/util.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  public httpOptions: object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public httpOptionsHideError = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: {
      showError: 'false'
    }
  };
  public httpOptionsMultipleLead = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: {
      signup_access: 'true'
    }
  };
  public baseUrl: string = environment.APP.BASE_URL;
  apiUrl = environment.APP.API_URLS;

  constructor(
    public http: HttpClient,
    private utilService: UtilService
  ) { }

  getBanner() {
    return this.http.get(this.baseUrl + this.apiUrl.GET_BANNER(), this.httpOptions);
  }

  getTrendingFR(currency, qparams?) {
    const KU = this.utilService.getCookie('_ku');
    return this.http.get(this.baseUrl + this.apiUrl.GET_TRENDING_FR(currency, KU, qparams), this.httpOptions);
  }

  getFrequentVisitedCampaigns(currency, campaignsIds, qparams?) {
    const KU = this.utilService.getCookie('_ku');
    return this.http.get(this.baseUrl + this.apiUrl.GET_FREQUENTVISITEDCAMPAIGNS(currency, campaignsIds, KU, qparams), this.httpOptions);
  }
  
  getUrgentFR() {
    return this.http.get(this.baseUrl + this.apiUrl.GET_URGENT_FR(), this.httpOptions);
  }

  getSuccessStories() {
    return this.http.get(this.baseUrl + this.apiUrl.GET_SUCCESS_STORIES(), this.httpOptions);
  }
  getSuccessStoriesList(qparam?) {
    return this.http.get(this.baseUrl + this.apiUrl.SUCCESS_STORIES + qparam, this.httpOptions);
  }

  getDraftData(token: string) {
    return this.http.get(this.baseUrl + this.apiUrl.GET_DRAFT_FR(), this.httpOptionsHideError);
  }

  discardDraft(token: string) {
    return this.http.put(this.baseUrl + this.apiUrl.DISCARD_DRAFT(), {}, this.getHttpOptionsWithToken(token));
  }

  postLeadData(data, hideError?, allowMultipleLeads?) {
    let options;
    if (hideError && allowMultipleLeads) {
      const params = {
        params: {
          signup_access: true,
          showError: false
        }
      };
      options = {
        ...this.httpOptions,
        ...params
      };
    } else {
      if (hideError) {
        options = hideError ? this.httpOptionsHideError : this.httpOptions;
      }
      if (allowMultipleLeads) {
        options = allowMultipleLeads ? this.httpOptionsMultipleLead : this.httpOptions;
      }
    }
    return this.http.post(this.baseUrl + this.apiUrl.POST_LEAD_DATA(), data, options);
  }

  updateLeadData(data) {
    return this.http.put(this.baseUrl + this.apiUrl.POST_LEAD_DATA(), data, this.httpOptions);
  }

  /**Get Merged Causes */
  getMergedCauses() {
    const PersonalCauses = this.http.get(this.baseUrl + 'causes/20?with=child&filter=id;info_1');
    const creativeCauses = this.http.get(this.baseUrl + 'causes/49?with=child&filter=id;info_1');
    return forkJoin([PersonalCauses, creativeCauses]);
  }

  getHttpOptionsWithToken(token) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  getTrendingStory() {
    return this.http.get(this.baseUrl + this.apiUrl.GET_TRENDING_STORY(), this.httpOptions);
  }

  getPressClubData(page) {
    const url = this.baseUrl + this.apiUrl.GET_PRESSCLUB_DATA;
    return this.http.get(url + `?page=${page}&with=media&orderBy=datetime&sortedBy=desc`, this.httpOptions);
  }
}
