import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { UtilService } from '../../services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    private util: UtilService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state);
  }
  checkLogin(state: RouterStateSnapshot) {
    if (this.util.vars.isVariableLogin) {
      return true;
    }
    const data = this.util.getUserData();
    if (data) {
      return true;
    } else {
      const qPrams = Object.assign({ redirect_uri: state.url.split('?')[0] }, state.root.queryParams);
      this.router.navigate(['/new/signin'], { queryParams: qPrams });
      return false;
    }
  }
}
