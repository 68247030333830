<ng-container *ngIf="type === 'draft'; else elseTemplate">
  <div class="draft-container">

    <div class="img-wrapper">
      <img loading="lazy" data-src="{{draftImg}}" (error)="utilService.defaultImg" class="draft-bg" alt="data?.title" />
    </div>

    <div class="draft-body">

      <button mat-icon-button mat-dialog-close class="close-ico" *ngIf="isHome"><mat-icon>close</mat-icon></button>

      <h1 [hidden]="!hideDiscardButton">Welcome back {{ userData?.full_name }}</h1>
      <h1 [hidden]="hideDiscardButton">You have already started a Fundraiser</h1>

      <p>
        Would you like to complete the Fundraiser you started on <span>{{startDate}}</span>  from where you left off?
      </p>

      <div class="fixed-btn">
        {{draftUrl}}
        <span (click)="goToDraft()" class="btn edit-btn" mat-raised-button [ngClass]="{'mb-0': hideDiscardButton}">
          <i class="material-icons edit-icon">edit</i>
          Continue editing draft <span *ngIf="!isMobile">Fundraiser</span>
        </span>
        <!-- <a class="btn start-btn" [hidden]="hideDiscardButton" (click)="discardDraft()" mat-stroked-button>Discard and Start a New Fundraiser</a> -->
      </div>

    </div>

  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="under-review-container">
    <div class="close-under-review">
      <button mat-icon-button mat-dialog-close class="close-ico" (click)="onUnderReviewClose()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="title">Your Fundraiser Is {{type === 'underReview' ? 'Under Review': type === 'approved' ? 'Already Live!': ''}}</div>
    <div class="sub-title" *ngIf="type === 'underReview'">Our team is verifying your fundraiser, reach out to us for any query</div>
    <div class="fr-data-container d-flex">
      <div class="fr-image-container">
        <img loading="lazy" data-src="{{draftImg}}" (error)="utilService.defaultImg" alt="data?.title" />
      </div>
      <div class="fr-title-container">
        <div class="fr-title">
          {{ data.title }}
        </div>
        <div class="amount-requested">
          ₹ {{ data.amount_requested  | inrFormat:'inr' }}
        </div>
      </div>
    </div>
    <div class="question-to-edit">You may Edit this Fundraiser or {{ type === 'underReview' ? 'Upload Documents for Review' : type === 'approved' ? 'Create a new one': ''}}</div>
    <div class="action-btns">
      <button class="btn btn-contact-us" (click)="redirectToContactUs()" mat-stroked-button *ngIf="type==='underReview'">Query? Contact us</button>
      <button class="btn btn-contact-us" (click)="createNewCampaignFromPopup()" mat-stroked-button *ngIf="type==='approved'">Start New Fundraiser</button>
      <button class="btn btn-edit"  (click)="openEditFr()" color="primary" mat-raised-button>Edit Fundraiser</button>
    </div>
  </div>
</ng-template>

<ng-template #talktoUsTemp>
  <app-module-loader moduleId="talktous-popup" selector="app-common-talktous-modal" [load]="loadTalktousPopup" [inputs]="{
    leadMailToInput: 'help@ketto.org'}"></app-module-loader>
</ng-template>
