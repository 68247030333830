import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { clevertap_script, fb_pixel, gtm_script, zoho_script, blitzlamaScript, verloop_script, microsoft_clarity, zoho_AB } from './external_js';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: 'googlemaps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDCF-b1N89za5IiNd95L27vx-XyFzUeoCE&callback&libraries=places'
  },
  {
    name: 'facebooksdk',
    src: 'https://connect.facebook.net/en_US/all.js'
  },
  {
    name: 'intl_tel_input',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.7/js/intlTelInput.min.js'
  },
  {
    name: 'intl_tel_input_util',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.7/js/utils.js'
  },
  {
    name: 'gcse',
    src: 'https://cse.google.com/cse.js?cx=009727578198181226831:wayabw0ziuy'
  },
  {
    name: 'webfont',
    src: 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'
  },
  {
    /**
     * Pass {id: 'ze-snippet'} to loadScript function in second param to load Zendesk
     */
    name: 'zendesk',
    // src: 'https://static.zdassets.com/ekr/snippet.js?key=9a1dc368-9cf8-4506-b6c7-47b1c882c6eb'
    src: zoho_script
  },
  {
    /**
     * Pass {nonce: <nonce value>} to loadScript function in second param to load zoho
     */
    name: 'zoho',
    src: 'https://desk.zoho.com/portal/api/web/inapp/394045000008699169?orgId=689562824'
  },
  {
    name: 'verloop',
    src: verloop_script
  },
  {
    name: 'zoho_internal',
    src: 'assets/js/zoho.js'
  },
  {
    name: 'stripe',
    src: 'https://js.stripe.com/v2/'
  },
  {
    name: 'jquery',
    src: 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.2/jquery.min.js'
  },
  {
    name: 'juspay',
    src: `${environment.juspay}pay.js`
  },
  {
    name: 'pusher',
    src: `https://cdnjs.cloudflare.com/ajax/libs/pusher/4.3.1/pusher.min.js`
  },
  {
    name: 'twitter',
    src: `https://platform.twitter.com/widgets.js`
  },
  {
    name: 'vwo',
    src: 'assets/js/vwo.js'
  },
  {
    name: 'gtm',
    src: gtm_script
  },
  ,
  {
    name: 'clevertap',
    src: clevertap_script
  },
  {
    name: 'razor_pay',
    src: 'https://checkout.razorpay.com/v1/checkout.js'
  },
  {
    name: 'razor_pay_card',
    src: 'https://checkout.razorpay.com/v1/razorpay.js'
  },
  {
    name: 'razor_pay_custom_checkout',
    src: 'https://checkout.razorpay.com/v1/razorpay.js'
  },
  {
    name: 'lightgallery',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/js/lightgallery.min.js'
  },
  {
    name: 'lg-zoom',
    src: '/assets/js/lg-zoom.min.js'
  },
  {
    name: 'lg-hash',
    src: '/assets/js/lg-hash.min.js'
  },
  {
    name: 'lg-thumbnail',
    src: '/assets/js/lg-thumbnail.min.js'
  },
  {
    name: 'fb_pixel_script',
    src: fb_pixel
  },
  {
    name: 'card-validator',
    src: '/assets/js/card-validator.js'
  },
  {
    name: 'blitzlamaScript',
    src: blitzlamaScript
  },
  {
    name: 'google_login',
    src: 'https://accounts.google.com/gsi/client'
  },
  {
    name: 'microsoft_clarity',
    src: microsoft_clarity
  },
  {
    name: 'google_optimize',
    src: `https://www.googleoptimize.com/optimize.js?id=${environment.google_optimize}`
  },
  {
    name: 'zoho_AB',
    src: zoho_AB
  },
  {
    name: 'animejs',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/animejs/3.2.2/anime.min.js'
  },
  {
    name: 'google_login_button',
    src: 'https://apis.google.com/js/platform.js'
  }
];
@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  private scripts: any = {};

  constructor(
    @Inject(DOCUMENT) private document: any
  ) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string, params?: any, full_script?: true) {
    return new Promise((resolve, reject) => {
      try {
        // resolve if already loaded
        if (this.scripts[name].loaded) {
          resolve({ script: name, loaded: true, status: 'Already Loaded' });
        } else {
          // load script
          const script: any = this.document.createElement('script');
          script.type = 'text/javascript';
          script.setAttribute('rel', 'preconnect');
          full_script ? script.innerHTML = this.scripts[name].src : script.src = this.scripts[name].src;
          // script.async = true;
          script.defer = true;
          if (params) {
            for (const key in params) {
              if (params.hasOwnProperty(key)) {
                script.setAttribute(key, params[key]);
              }
            }
          }
          if (script.readyState) {  // IE
            script.onreadystatechange = () => {
              if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                this.scripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: 'Loaded' });
              }
            };
          } else {  // Others
            script.onload = () => {
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            };
          }
          script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
          this.document.getElementsByTagName('head')[0].appendChild(script);
        }
      } catch (error) {
        reject(false);
      }
    });
  }

}
