import { Component, OnInit } from '@angular/core';
import { EventsService, ScriptLoaderService } from '../../shared/services';

@Component({
  selector: 'app-verloop-chat',
  templateUrl: './verloop-chat.component.html',
  styleUrls: ['./verloop-chat.component.scss']
})
export class VerloopChatComponent implements OnInit {

  isLoading;

  constructor(
    private events: EventsService,
    private scripts: ScriptLoaderService
  ) { }

  ngOnInit(): void {
    this.checkIfScriptLoaded();
  }

  async loadChatBox() {
    this.isLoading = true;
    try {
      await this.scripts.loadScript('verloop', '', true);
    } catch (error) {
      this.isLoading = false;
    }
  }
  async checkIfScriptLoaded() {
    try {
      await this.events.isScriptReady('verloop_loaded');
      if ((<any>window)?.Verloop) {
        this.isLoading = false;
        document.getElementById('verloopChatBox').style.display = 'none';
        (<any>window).Verloop.livechat.openWidget();
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

}
