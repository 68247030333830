import { UtilService } from './../util/util.service';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public httpOptions: object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public coreApi: string = environment.APP.CORE_API;
  apiUrl = environment.APP.API_URLS;
  baseUrl = environment.APP.BASE_URL;

  constructor(public http: HttpClient, private utils: UtilService) { }

  getheaders(showError?) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    if (showError) {
      Object.assign(options, { params: { showError: showError } });
    }
    return options;
  }

  headerWithContentType(contentType: string = 'application/json'): any {
    return new HttpHeaders({
      'Content-Type': contentType,
    });
  }

  delete(url, showError?): Observable<any> {
    const apiUrl = this.baseUrl + url;
    return this.http.delete(apiUrl, {});
  }

  get(url, showError?): Observable<any> {
    const apiUrl = this.baseUrl + url;
    return this.http.get(apiUrl, this.getheaders(showError));
  }

  post(url, data?, showError?): Observable<any> {
    const apiUrl = this.baseUrl + url;
    return this.http.post(apiUrl, JSON.stringify(data), this.getheaders(showError));
  }

  put(url: string, data?: any, showError?: any, contentType?: any): Observable<any> {
    const apiUrl = this.baseUrl + url;
    const options = {
      headers: this.headerWithContentType(contentType),
    };
    return this.http.put(apiUrl, data ? JSON.stringify(data) : null, options);
  }

  fileUpload(url, data, showError?): Observable<any> {
    const apiUrl = this.baseUrl + url;
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      }),
    };
    const fd = new FormData();
    for (const key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    return this.http.post(apiUrl, fd, options);
  }

  saveGoogleFrom(fd) {
    const form_data = new FormData();
    // tslint:disable-next-line: forin
    for (const key in fd) {
      form_data.append(key, fd[key]);
    }

    this.utils.isToken = false;
    return this.http
      .post('https://script.google.com/macros/s/AKfycbxqmUK0sWjny5HWSYaarJlP7iafCq-r3pNHKX9sdWG8Z0hhHF_IbOcj6O0pzgB41PxT/exec',
        form_data);

  }

  /**
   * Applies coupons
   * @param campaignId campaign id
   * @param couponId coupon id
   * @returns observable
   */
  applyCoupon(campaignId: number, couponDetails: { coupon_id: number }): Observable<any> {
    return this.http.post(this.baseUrl + this.apiUrl.GET_COUPON_URL(campaignId), couponDetails, this.httpOptions);
  }

  getShortURL(payload): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        return this.http.post<{ data: { data: { short_url: string } } }>(this.baseUrl + this.apiUrl.GET_SHORT_URL(), payload, this.httpOptions).
          subscribe(res => {
            resolve(res.data.data.short_url);
          });
      } catch (error) {
        reject(false);
      }
    });
  }
}
