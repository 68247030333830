import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWithUsComponent } from './components/chat-with-us/chat-with-us.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ChatWithUsComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [ChatWithUsComponent]
})
export class ChatWithUsModule { }
