import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { UtilService } from "../../services";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HfGuard implements CanActivate {
  constructor(
    private util: UtilService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkHfState(state);
  }

  checkHfState(state): boolean {
    let data: any = this.util.getUserData();
    return this.checkHfRedirection(data, state);
  }

  checkHfRedirection(data, state): boolean {
    if (data && data.last_health_first_order === null) {
      if (state.url.includes('/healthfirst')) {
        return true;
      } else  {
        this.util.router.navigate(['/healthfirst']);
      }
    } else if (data && data.userHealthPolicy !== null && data.last_health_first_order !== null &&
      data.last_health_first_order?.order_id === data.userHealthPolicy?.orderId && 
      data.userHealthPolicy?.status !== 1) {
      if (state.url.includes('/healthfirst')) {
        return true;
      } else  {
        this.util.router.navigate(['/healthfirst']);
      }
    } else if (data && data.userHealthPolicy !== null && !data.is_medibuddy && data.last_health_first_order?.order_id === data.userHealthPolicy?.orderId) {
      // bima sip retention
      window.location.href = environment.sipretention_domain + '/healthfirst';
    } else if ((data && data.is_medibuddy) || (data && !data.is_medibuddy && data.userHealthPolicy !== null && data?.last_health_first_order !== null && data?.last_health_first_order?.order_id !== data.userHealthPolicy?.orderId)) {
      if (state.url.includes('/healthfirst/thankyou')) {
        return true;
      } else  {
        this.util.router.navigate(['/healthfirst/thankyou']);
      }
    } else if (data && data.userHealthPolicy === null && data.last_health_first_order !== null) {
      if (state.url.includes('/healthfirst/thankyou')) {
        return true;
      } else  {
        this.util.router.navigate(['/healthfirst/thankyou']);
      }
    }
    return true;
  }
}