<div class="page-not-found-wrapper">
  <div class="main-image-wrapper">
    <img data-src="/assets/images/404pageNew/404new.png" loading="lazy" alt="404 image" />
  </div>
  <div class="main-content">
    <div class="title">
      {{pageContent.title}}
    </div>
    <div class="main-title">
      {{pageContent.mainTitle}}
    </div>
    <div class="desc">
      {{pageContent.desc}}
    </div>
  </div>
  <div class="action">
    <div class="btn-item" *ngFor="let item of pageContent.links">
      <a [href]="item.link" (click)="sendSystemEvents('other', item.title)">
        <span class="link-text">
          {{item.title}}
        </span>
        <span class="link-icon">
          <mat-icon class="mat-18">arrow_forward_ios</mat-icon>
        </span>
      </a>
    </div>
  </div>
</div>