export const FOOTER_SEO_CONTENT_MEDICAL_FUNDRAISING = {
  mainTitle: 'How Medical Crowdfunding can help you pay your Medical Bills?',
  sections: [
    {
      id: 1,
      heading: 'Use Ketto’s Medical Fundraising to pay expensive medical bills and hospitalisation charges',
      subHeading: '',
      desc: `Ketto’s Online Crowdfunding, especially Ketto’s medical fundraising, is gaining immense popularity within India. Online Medical Fundraising empowers individuals by helping them pay expensive medical bills and expensive hospital treatment costs. Start a Medical Fundraising Campaign with Ketto today, and stop worrying about rising medical bills, or post-hospitalization expenses.
            <p class="desc mt-2"> Ketto is the first online crowdfunding platform in India to introduce medical fundraising at a <a href="https://www.ketto.org/support/plans-and-pricing.php">0% percent platform fee*</a> </p>
            <p class="desc"> 0% platform fee* ensures your fundraiser receives maximum funds. </p>
            <p class="desc">Medical fundraising option on Ketto helps individuals, NGOs, and organisations raise funds for medical emergency situations such as the second wave of Covid-19 pandemic, cancer chemotherapy, kidney & liver transplant, accidents, expensive ICU bills and NICU care quickly. </p>
            <p class="desc"> Through Ketto’s Medical Fundraising, more than 55+ lakh donors have raised funds with an industry-leading success rate of above 70%—this number is constantly growing every day. We at Ketto would like to invite you to try Medical Fundraising for near and dear ones or donate to a cause you care most about.</p>`,
      children: null
    },
    {
      id: 2,
      heading: '',
      subHeading: 'How to Start Medical Fundraising on Ketto?',
      desc: `Starting a Medical Fundraising on Ketto’s 0% platform* is very simple. It takes just 3 minutes, and signing up is absolutely free*.
            <p class="desc"> Just sign up, fill in details of what you need funds for and hit submit. Once the campaign is live, you can share it among your WhatsApp groups, friend circles and social media to raise funds for a friend or loved one’s treatment. Every time your fundraiser is shared on social media, it increases the chances for you to raise more contributions and helps you achieve your target goal amount quicker.</p>`,
      children: null
    },
    {
      id: 3,
      heading: '',
      subHeading: 'What is Crowdfunding or Online Fundraising?',
      desc: `At its core, crowdfunding is an activity of gathering a small number of funds from a large number of people. We are all a part of a broader community and the presence of the internet and its collective media lattice; empowers us as individuals to arrange funds from people across the world. However, it raises questions about the credibility of campaigns on ketto. `,
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'Is Ketto Genuine?',
      desc: `Yes!
            <p class="desc">Ketto is a Crowdfunding Platform based out of India</a>
            The purpose of Ketto as an online donation-based crowdfunding or fundraising platform to fill a growing void within the age-old Indian culture of giving has grown on to become a revolution.</p>
            <p class="desc"> At Ketto, we strive to raise awareness and explain in brief different measures taken to ensure that every campaign on Ketto is genuine. You can click <a href="https://www.ketto.org/blog/are-campaigns-in-ketto-genuine">here</a>
            to know more.</p>`,
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'What is Ketto’s Vision?',
      desc: `As time passes, Ketto strives to help more individuals and their loved ones <a href="https://www.ketto.org/cause/cancer-crowdfunding"> gather funds to pay for expensive cancer treatments</a>
            expensive medical treatments like chemotherapy, radiotherapy, kidney transplants and open-heart surgery, to name a few. Ketto’s vision is to make healthcare accessible to all.`,
      children: null
    },
    {
      id: 6,
      heading: '',
      subHeading: 'Why should you choose Online Medical Crowdfunding?',
      desc: `Numerous insurance providers reject an individual’s medical insurance policy due to strict eligibility criteria. Similarly, most banking institutions in India deny loans to fund expensive medical emergencies due to strict eligibility and regulation criteria.
            <p class="desc"> By introducing Online Medical Crowdfunding Option, Ketto has pioneered a shift in fundraising. This change in fundraising for medical emergencies has encouraged more individuals like yourself to turn to and start online medical fundraisers.</p>`,
      children: null
    },
    {
      id: 7,
      heading: '',
      subHeading: 'Let us explore how fundraising on Ketto is the best option for you?',
      children: [
        {
          id: 1,
          heading: 'Starting A Fundraiser on Ketto Is Simple',
          desc: 'Sign Up. Enter basic details about yourself, the cause you’re raising funds for and the beneficiary details. After your fundraiser is approved, our dedicated fundraiser managers will guide you throughout your fundraising journey.',
          list: null
        },
        {
          id: 2,
          heading: 'Medical Crowdfunding Is Quick, and funds are transferred directly.',
          desc: 'When your near and dear ones are diagnosed with life-threatening diseases, it’s devastating emotionally as well as financially. Most medical emergencies need the treatment to begin at the earliest. By giving you a platform to share your emergency, Ketto’s online crowdfunding platform helps you gather funds quickly. Our platform allows withdrawal of funds at any point during your fundraiser.',
          list: null
        },
        {
          id: 3,
          heading: 'Online Medical Fundraising Reduces Financial Burden',
          desc: 'Anybody can encounter a medical emergency; it comes without warning. Often, health insurance and personal savings are just not enough to cover all the expensive medical bills. Crowdfunding can help you repay these expensive medical and post-hospitalisation bills by accepting small donations from a large group of generous people.',
          list: null
        },
        {
          id: 4,
          heading: 'Asking For Funds Isn’t Easy.',
          desc: 'When in need, asking for help from people and describing financial problems face-to-face is awkward and inconvenient. <a href="https://www.ketto.org/crowdfunding"> Online crowdfunding</a> makes the process of asking for funds more straightforward and more seamless. ',
          list: [
            {
              id: 1,
              title: 'Why Ketto is the best option to Raise Funds for Medical Expenses & Bills?',
              desc: 'Ketto bridges the gap between beneficiaries and donors. The key features that make Ketto stand apart from others are:',
              list: [
                {
                  id: 1,
                  val: 'Starting a fundraiser on Ketto is absolutely free.',
                },
                {
                  id: 2,
                  val: '24x7 assistance from dedicated fundraiser managers throughout your fundraising journey.',
                },
                {
                  id: 3,
                  val: '24x7 assistance from dedicated fundraiser managers throughout your fundraising journey.',
                },
                {
                  id: 4,
                  val: 'You can withdraw your funds at any point during your fundraiser.',
                },
                {
                  id: 5,
                  val: 'Get instant updates on your fundraiser’s progress on a real-time dashboard.',
                },
                {
                  id: 6,
                  val: 'Accepts donations from all debit & credit cards, net banking, UPI and online wallets.',
                }
              ],
              desc2: `Today, Ketto offers several features like SIP (<a href="https://www.ketto.org/new/sip?utm_source=internal_Ketto&utm_term=footer_seo_medical">Social Impact Plan </a>) & <a href="https://play.google.com/store/apps/details?id=in.healthnest&hl=en_IN&gl=US">Health Nest</a> to enhance your fundraising experience and help you with mental health-related issues.`
            }
          ]
        },
      ]
    },
  ],
  searches: {
    data: `<a href="https://www.ketto.org/blog/twenty-five-quick-fundraising-ideas">25 Creative Fundraising Ideas</a> |
        <a href="https://www.ketto.org/blog/zolgensma-price-in-india">Zolgensma: Price in India & Availability</a> |
        <a href="https://www.ketto.org/campaign/crowdfunding-for-education.php">Education Crowdfunding </a> |
        <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php">NGO Fundraising</a> |
        <a href="https://www.ketto.org/blog/social-impact-plan-from-ketto-is-taking-fundraising-to-the-next-level">  Monthly Donation Plan (SIP)</a> |
        <a href="https://www.ketto.org/healthfirst">Buy Best Family Health Plan</a> |
        <a href="https://www.ketto.org/blog/how-to-start-crowdfunding-for-animal-rescue">Crowdfunding for Animal Rescue</a> |
        <a href="https://www.ketto.org/blog/how-can-i-raise-money-for-charity">Raise Money for Charity</a> |
        <a href="https://www.ketto.org/blog/five-quick-fundraising-ideas"> Fundraising Ideas </a> |
        <a href="https://www.ketto.org/blog/what-are-the-types-of-crowdfunding-in-india">Types of Crowdfunding</a> |
        <a href="https://www.ketto.org/blog/crowdfunding-for-teachers-how-to-raise-money-for-your-classroom"> Crowdfunding for Teachers</a>`
  }

};
