<!-- Router outlet -->
<app-google-onetap-login *ngIf="router?.url?.match('stories|fundraiser') && !params?.mini_story && !router?.url?.match('lg')"></app-google-onetap-login>
<div>
  <router-outlet></router-outlet>
</div>
<!-- Http call loader -->
<!-- <app-http-loader></app-http-loader> -->
<!-- <app-http-loader *ngIf="!loadingRouteConfig"></app-http-loader> -->
<!-- Lazy loaded modules loader -->
<div *ngIf="loadingRouteConfig" class="content-wrapper">
  <div class="l-placeholder">
    <div class="l-animated-background"></div>
  </div>
</div>
