<!-- Top navigation -->
<div *ngIf="config?.showHeader">
    <app-module-loader [moduleId]="'common-header'" [selector]="'app-common-header'" [load]="config?.showHeader" [inputs]="{config: config}" [outputs]="{logoClickEmit: headerLogoClickListner}"></app-module-loader>
</div>
<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>

<!-- Footer -->
<div *ngIf="config?.showFooter">
    <app-module-loader [moduleId]="'footer'" [selector]="'app-footer'" [load]="config?.showFooter"></app-module-loader>
</div>
<!-- <app-footer *ngIf="config?.showFooter"></app-footer> -->

<app-footer-seo *ngIf="config?.showFooterSEO"></app-footer-seo>

<app-hospital-footer-seo *ngIf="config?.showHospFooterSeo"></app-hospital-footer-seo>

<!-- verloop -->
<app-verloop-chat [hidden]="!config?.zendesk"></app-verloop-chat>

<!-- Abandoned cart -->
<app-abandoned-cart *ngIf="config && !config?.hideAbandonedCart" [config]="config"></app-abandoned-cart>

<app-home-v2-dock *ngIf="isMobile && config?.enableDock" />