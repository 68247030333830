<div class="dialog-wrapper">
  <div mat-dialog-title>
    <div class="title">{{data?.title}}</div>
    <app-close-btn type="dialog" top="10px" right="10px"></app-close-btn>
  </div>
  <mat-dialog-content class="mat-typography" [ngClass]="data?.contentClass">
    <ng-template [ngTemplateOutlet]="data?.content"></ng-template>
  </mat-dialog-content>
  <ng-container *ngIf="data?.footer">
    <mat-dialog-actions [ngClass]="data?.footerClass">
      <ng-template [ngTemplateOutlet]="data?.footer"></ng-template>
    </mat-dialog-actions>
  </ng-container>
</div>


<ng-template #deleteTemplate>
  <div class="subtitle">Are you sure you want to delete this {{data.deleteMsg}}?</div>
  <div class="mt-2">
    <app-button label="Yes, Delete" type="submit" btnClass="delete-btn full-width" class="full-width" [isLoading]="isLoading" (click)="deleteComment()"></app-button>
  </div>
  <div class="mt-2">
    <app-button label="Cancel" type="submit" btnClass="primary-btn full-width" class="full-width" mat-dialog-close></app-button>
  </div>
</ng-template>