import { IUser } from './../../models/user.model';
import { VariablesService } from './../variables/variables.service';
import { StorageService } from './../storage/storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../util/util.service';

declare const FB: any;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  public APIURL = environment.APP.BASE_URL;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public leaderBoardImage;
  public beneficiaryImage;
  public youTubeVideo;
  private entityId;
  // tslint:disable-next-line:max-line-length
  draftParams = 'media;beneficiaryname;beneficiaryrelation;beneficiaryage;disease;manager.entity;rewards;beneficiary;about;shortdescription;cause;owner;hospital;patientNumber;owner.qualification;owner.occupation;owner.salary;brandsurvey';
  public micrositeCampaigns = {
    entity_type: '',
    entity_id: ''
  };

  constructor(
    public http: HttpClient,
    private storageService: StorageService,
    private variableService: VariablesService,
    private util: UtilService
  ) { }

  getFacebookImages(id) {
    FB.api('/' + id + '/albums?fields=id,name', (response) => {
      return response;
    });
  }
  /**Get Entity id from local storage */
  getEntityId() {
    const data = this.util.getUserData();
    this.entityId = data.id;
  }
  /**
   * Get the MERGED CAUSES
   */
  getMergedCauses() {
    const url = this.APIURL + environment.APP.API_URLS.GET_MERGED_CAUSES;
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Get the causes on the basis of
   * type of the user
   * @param id
   */
  getCauses(id) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAUSES;
    return this.http.get(url + id + '?with=child', this.httpOptions);
  }
  /**
   * Get the causes on by NGO id
   * @param id NGO id
   */
  getCausesByNGO(id) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAUSE_BY_NGO_ID(id);
    return this.http.get(url + '?filter=full_name;id', this.httpOptions);
  }
  /**
   * Get the Names of the NGO's
   * @param name Name of the NGO
   */
  getNGOs(name?: string) {
    const url = this.APIURL + environment.APP.API_URLS.GET_NGOS;
    if (!name) { name = ''; }
    const options = {
      'fname': name
    };
    if (this.micrositeCampaigns.entity_type === 'events') {
      options['event_id'] = this.micrositeCampaigns.entity_id;
    }
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams({ fromObject: options })
    };
    return this.http.get(url, httpOptions);
  }
  /**
   * Get the details of campaign
   * @param draftId Draft id
   */
  getCampaign(draftId) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGN(draftId);
    return this.http.get(url + '?with=' + this.draftParams, this.httpOptions);
  }
  /**
   * Get the details of campaign draft
   * @param draftId Draft id
   */
  getCampaignDraft(draftId) {
    this.getEntityId();
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGN_DRAFT_DETAILS(this.entityId, draftId);
    return this.http.get(url + '?with=' + this.draftParams, this.httpOptions);
  }

  /**
   * Save basic details of campaign in draft
   * @param data All the required data to create campaign
   */
  createCampaign(data) {
    if (this.variableService.isFOS) {
      const userdata: IUser = this.util.getUserData();
      data['creator_entity_details_id'] = userdata.id;
    }
    const url = this.APIURL + environment.APP.API_URLS.CREATE_NEW_CAMPAIGN(data.parent_cause_id);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Update basic details of campaign in draft
   * @param data All the required data to create campaign
   */
  updateCampaign(data, id) {
    const url = this.APIURL + environment.APP.API_URLS.UPDATE_CAMPAIGN(id);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }

  /**
   * Upload image for cause wich contains
   * file and the cropped image ratios
   * @param data Image and croppered image dimensions @param id parent cause id
   */
  uploadImageLeaderboard(data, id) {
    const url = this.APIURL + environment.APP.API_URLS.UPLOAD_LEADERBOARD_IMAGE(id);
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.post(url, data, options);
  }

  /**
   * Upload image for Beneficiary
   * file and the cropped image ratios
   * @param data Image and croppered image dimensions @param id parent cause id
   */
  uploadImageBeneficiary(data, id) {
    const url = this.APIURL + environment.APP.API_URLS.UPLOAD_BENEFICIARY_IMAGE(id);
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.post(url, data, options);
  }
  /**
   * Upload image in Editor
   * @param data Image and croppered image dimensions @param id parent cause id
   */
  uploadEditorImage(data, id) {
    const url = this.APIURL + environment.APP.API_URLS.UPLOAD_EDITOR_IMAGE(id);
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.post(url, data, options);
  }
  /**
   * Delete a particular media image from campagin
   * @param draftId Campaign Draft ID
   * @param mediaId Media ID
   */
  deletMedia(draftId, mediaId) {
    const url = this.APIURL + environment.APP.API_URLS.DELETE_LEADERBOARD_IMAGE(draftId, mediaId);
    return this.http.delete(url, this.httpOptions);
  }

  /**
   * To upload youtube video just provide url
   * of the youtube uploaded video to this API
   * @param data Video URL and `display_type_1` flag required
   * @param draftId Draft ID of the campaign
   */
  uploadYoutubeVideo(data, draftId) {
    const url = this.APIURL + environment.APP.API_URLS.UPLOAD_YOUTUBE_VIDEO(draftId);
    return this.http.post(url, data, this.httpOptions);
  }

  /**
   * Create new reward
   * @param data Data requred to create reward
   * @param id Current draft id
   */
  addReward(data, id) {
    const url = this.APIURL + environment.APP.API_URLS.ADD_REWARD(id);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Update the particular reward of campign
   * @param data required data which is to updated
   * @param draftId Campaign Draft ID
   * @param rewardId Reward's ID
   */
  update(data, draftId, rewardId) {
    const url = this.APIURL + environment.APP.API_URLS.UPDATE_REWARD(draftId, rewardId);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Get the details of the single reward
   * @param draftId Campaign Draft ID
   * @param rewardId Reward's ID
   */
  getReward(draftId, rewardId) {
    const url = this.APIURL + environment.APP.API_URLS.GET_REWARD_BY_ID(draftId, rewardId);
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Get the list of the rewards
   * under perticular campaign
   * @param draftId Campaign Draft ID
   */
  getRewards(draftId) {
    const url = this.APIURL + environment.APP.API_URLS.GET_ALL_REWARDS(draftId);
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Delete a particular reward from campagin
   * @param draftId Campaign Draft ID
   * @param rewardId Reward's ID
   */
  deletReward(draftId, rewardId) {
    const url = this.APIURL + environment.APP.API_URLS.DELETE_REWARD(draftId, rewardId);
    return this.http.delete(url, this.httpOptions);
  }
  /**
   * Send the data to system envent service
   * @param data event object to push in GTM
   */
  sendSystemEventGTM(data) {
    const url = environment.APP.CORE_API + environment.APP.API_URLS.SYSTEM_EVENT_GTM;
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      }),
      params: data
    };
    return this.http.get(url, options);
  }
  /**
   * Publish the campaign for Approval
   * @param data Status code for pusblish
   * @param draftId Draft Id
   */
  publishCampaign(data, draftId) {
    const url = this.APIURL + environment.APP.API_URLS.PUBLISH_CAMPAIGN(draftId);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }

  /**
   * Get The assistence from company's
   * backend team
   * @param data Date required to fire enquiry
   */
  sendAssistenceEnquiry(data) {
    const httpOptionsHideError = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {
        showError: 'false'
      }
    };
    const url = this.APIURL + environment.APP.API_URLS.GET_LEAD;
    return this.http.post(url, JSON.stringify(data), httpOptionsHideError);
  }
  /**
   * Get medical disease listing
   */
  getDiseaseList(search: string, field: string) {
    const url = this.APIURL + environment.APP.API_URLS.DISEASE_LIST(search, field);
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Get medical hospital listing
   */
  getHospitalList(search: string, field: string) {
    const url = this.APIURL + environment.APP.API_URLS.HOSPITAL_LIST(search, field);
    return this.http.get(url, this.httpOptions);
  }
}
