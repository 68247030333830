import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/components/main-layout/main-layout.component';
import { LoginViaTokenComponent } from './shared/components/login-via-token/login-via-token.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { PaymentRoutingComponent } from './shared/components/payment-routing/payment-routing.component';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { SacGuard } from './shared/guards/sac/sac.guard';
import { SipGuard } from './shared/guards/sip/sip.guard';
import { HfGuard } from './shared/guards/health-first/health-first.guard';

const routes: Routes = [
  /*Redirect old php to new sac*/
  {
    path: 'campaign/start_new_campaign.php',
    redirectTo: 'new/crowdfunding/medical'
  },
  {
    path: 'new/crowdfunding',
    children: [
      {
        path: 'non-medical',
        canActivate: [SacGuard],
        loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: 'medical',
        canActivate: [SacGuard],
        loadChildren: () => import('./medical-campaign/medical-campaign.module').then(m => m.MedicalCampaignModule)
      },
      {
        path: '',
        loadChildren: () => import('./sac-signup/sac-signup.module').then(m => m.SacSignupModule)
      }
    ]
  },
  {
    path: 'new/campaign/new',
    redirectTo: 'new/crowdfunding/medical'
  },
  {
    path: 'new/logout',
    component: LogoutComponent
  },
  {
    path: 'new/user/account',
    component: LoginViaTokenComponent
  },
  {
    path: 'new/payment-route',
    component: PaymentRoutingComponent,
    data: {
      page_name: 'payment-redirect-page',
      category: 'payment',
      lastOrderSkip: true
    }
  },
  {
    path: 'new/campaign-thank-you',
    loadChildren: () => import('./pages/campaign-create-thankyou/campaign-create-thankyou.routes').then(r => r.routes)
  },
  {
    path: 'new/login',
    loadChildren: () => import('./pages/auto-redirect/auto-redirect.routes').then(r => r.routes)
  },
  {
    path: 'new/show-widget',
    loadChildren: () => import('./show-widget/show-widget.module').then(m => m.ShowWidgetModule)
  },
  {
    path: 'new/access',
    loadComponent: () => import('./pages/token-login/token-login.component').then(c => c.TokenLoginComponent),
    data: {
      page_name: 'token_login'
    }
  },
  {
    path: 'new/test',
    loadComponent: () => import('./pages/test/test.component').then(c => c.TestComponent),
    data: {
      page_name: 'test_page'
    }
  },
  // {
  //   path: 'new/stories',
  //   loadChildren: () => import('./story-v2/story-v2.module').then(m => m.StoryV2Module)
  // },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'payment-failed',
        loadChildren: () => import('./pages/payment-failed/payment-failed.routes').then(r => r.routes)
      },
      {
        path: 'new/anniversary',
        loadChildren: () => import('./tenth-anniversary/tenth-anniversary.module').then(m => m.TenthAnniversaryModule)
      },
      {
        path: 'new/standalonetip',
        loadChildren: () => import('./standalone-tip/standalone-tip.module').then(m => m.StandaloneTipModule)
      },
      {
        path: 'fundraiser',
        loadChildren: () => import('./fundraiser/fundraiser.module').then(m => m.FundraiserModule)
      },
      {
        path: 'supportfundraiser',
        loadChildren: () => import('./support-fundraiser/support-fundraiser.module').then(m => m.SupportFundraiserModule)
      },
      {
        path: 'stories/savealife',
        loadChildren: () => import('./story-list/story-list.module').then(m => m.StoryListModule)
      },
      {
        path: 'stories/lg',
        loadChildren: () => import('./leadgenpage/leadgenpage.module').then(m => m.LeadgenPageModule)
      },
      {
        path: 'stories',
        loadChildren: () => import('./story/story.module').then(m => m.StoryModule)
      },
      {
        path: 'new/stories',
        loadChildren: () => import('./story-v3/story-v3.module').then(m => m.StoryV3Module)
      },
      {
        path: 'new/signin',
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule)
      },
      {
        path: 'new/kettokart/thankyou',
        loadChildren: () => import('./thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
      },
      {
        path: 'new/thankyou',
        loadChildren: () => import('./thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
      },
      {
        path: 'new/pledge-more',
        canActivate: [AuthGuard],
        loadChildren: () => import('./common/pledge-page/pledge-page.module').then(m => m.PledgePageModule)
      },
      {
        path: 'new/404',
        component: PagenotfoundComponent
      },
      {
        path: 'new/campaign/campaign_update',
        loadChildren: () => import('./update-page/update-page.module').then(m => m.UpdatePageModule)
      },
      {
        path: 'crowdfunding/fundraisers',
        loadChildren: () => import('./browse-campaign/browse-campaign.module').then(m => m.BrowseCampaignModule)
      },
      {
        path: 'new/campaign_created',
        loadChildren: () => import('./non-med-thankyou/non-med-thankyou.module').then(m => m.NonMedThankyouModule)
      },
      {
        path: 'new/browse',
        redirectTo: 'crowdfunding/fundraisers'
      },
      {
        path: 'crowdfunding',
        loadChildren: () => import('./how-it-works/how-it-works.module').then(m => m.HowItWorksModule)
      },
      {
        path: 'medical-finance-loans',
        loadChildren: () => import('./medical-finance-loans/medical-finance-loans.module').then(m => m.MedicalFinanceLoansModule)
      },
      {
        path: 'new/press-club',
        loadComponent: () => import('./common/press-club/press-club.component').then(m => m.PressClubComponent),
      },
      {
        path: 'new/insurance',
        loadChildren: () => import('./insurance-page/insurance-page.module').then(m => m.InsurancePageModule)
      },
      {
        path: 'new/twfbaahubaliauction',
        loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule)
      },
      {
        path: 'new/portfolio/welcome',
        loadChildren: () => import('./impact-landing/impact-landing.module').then(m => m.ImpactLandingModule)
      },
      {
        path: 'new/portfolio',
        canActivate: [AuthGuard, SipGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./portfolio-v3/portfolio-v3.module').then(m => m.PortfolioV3Module)
      },
      {
        path: 'cause',
        loadChildren: () => import('./medical-fundraising/medical-fundraising.module').then(m => m.MedicalFundraisingModule)
      },
      {
        path: 'new/payment',
        loadChildren: () => import('./common/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'new/about/about-us',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule)
      },
      {
        path: 'new/referral',
        loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule)
      },
      {
        path: 'new/sip-upi',
        loadChildren: () => import('./sip-upi-intent/sip-upi-intent.module').then(m => m.SipUpiIntentModule)
      },
      {
        path: 'new/success-stories',
        loadChildren: () => import('./success-stories/success-stories.module').then(m => m.SuccessStoriesModule),
      },
      {
        path: 'new/sip-onetime-onboard',
        loadChildren: () => import('./sip-onetime-onboard/sip-onetime-onboard.module').then(m => m.SipOnetimeOnboardModule)
      },
      {
        path: 'new/sip/wallet-recharge',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./wallet-recharge/wallet-recharge.module').then(m => m.WalletRechargeModule)
      },
      {
        path: 'new/sip/welcome',
        loadChildren: () => import('./non-card-v3/non-card-v3.module').then(m => m.NonCardV3Module)
      },
      {
        path: 'new/sip-portfolio-page',
        canActivate: [AuthGuard],
        loadChildren: () => import('./common/sip-thankyou-first/sip-thankyou-first.module').then(m => m.SipThankyouFirstModule)
      },
      {
        path: 'new/sip/payment-standalone',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./sip-payment-standalone/sip-payment-standalone.module').then(m => m.SipPaymentStandaloneModule)
      },
      {
        path: 'new/sip/change-payment',
        loadChildren: () => import('./payment-sip/payment-sip.module').then(m => m.ChangePaymentSipModule)
      },
      {
        path: 'new/sip/:cause',
        loadChildren: () => import('./pages/cause-dm-page/cause-dm-page.routes').then(r => r.routes)
      },
      {
        path: 'new/sip/medical/v2',
        loadChildren: () => import('./dm-page/dm-page.module').then(m => m.DmPageModule)
      },
      {
        path: 'new/sip',
        loadChildren: () => import('./referral-page-v2/referral-page-v2.module').then(m => m.ReferralPageV2Module)
        // loadChildren: () => import('./pages/dm/dm.routes').then(r => r.routes)
      },
      {
        path: 'new/cost-calculator',
        loadChildren: () => import('./medical-cost-calculator/medical-cost-calculator.module').then(m => m.MedicalCostCalculatorModule)
      },
      {
        path: 'deardoctor',
        loadChildren: () => import('./dear-doctor/dear-doctor.module').then(m => m.DearDoctorModule)
      },
      {
        path: 'new/sip-thankyou',
        loadChildren: () => import('./common/onboarding-sip-v1/onboarding-sip-v1.module').then(m => m.OnboardingSipV1Module)
      },
      {
        path: 'new/download-page',
        loadChildren: () => import('./download-page/download-page.module').then(m => m.DownloadPageModule)
      },
      {
        path: 'new/access-denied',
        loadComponent: () => import('./access-denied/access-denied.component').then(m => m.AccessDeniedComponent)
      },
      {
        path: 'hospitals',
        loadChildren: () => import('./hospitals/hospitals.routes').then(m => m.HOSPITALS_ROUTES)
      },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.routes').then(r => r.routes)
      }
    ]
  },
  {
    path: 'healthfirst',
    loadChildren: () => import('./health-first/health-first.routes').then(m => m.HEALTH_FIRST_ROUTES),
    canActivate: [HfGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
