import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { IDialogData } from '../dialog.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogRef: MatDialogRef<DialogComponent>;

  constructor(
    public dialog: MatDialog
  ) { }

  /** Mocking the dialog of existing material for reuse perpose
   *  @param template: Includes th dialog content
   *  @param config: dialog config
   */
  openDialog(template: IDialogData, config: MatDialogConfig): any {
    this.dialogRef = this.dialog.open(DialogComponent, {
      height: config.height,
      width: config.width,
      maxHeight: config.maxHeight || 'auto',
      maxWidth: config.maxWidth || 'auto',
      data: template,
      panelClass: config.panelClass,
      closeOnNavigation: config.closeOnNavigation || false
    });
    this.dialogRef.afterClosed().subscribe(result => { console.log('closed'); });
    return this.dialogRef;
  }

  openDialogViaTemplate(templateRef: TemplateRef<any>, config: MatDialogConfig): any {
    this.dialogRef = this.dialog.open(templateRef, {
      height: config.height,
      width: config.width,
      maxHeight: config.maxHeight || 'auto',
      maxWidth: config.maxWidth || 'auto',
      data: config.data || '',
      disableClose: config.disableClose || false,
      panelClass: config.panelClass || '',
      backdropClass: config.backdropClass || '',
      closeOnNavigation: config.closeOnNavigation || false
    });

    return this.dialogRef;
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  closeAll(): void {
    this.dialog.closeAll();
  }
}
