export interface ISipCause {
  sip_cause: string;
  amount: number;
  start_date?: any;
  plan?: string;
}

export const SupportedSipCauses = {
  medical: { text: 'Medical' },
  animals: { text: 'Animals' },
  education: { text: 'Education' },
  food_hunger: { text: 'Food & Hunger' },
  elderly: { text: 'Elderly Care' },
  women_girls: { text: 'Women Empowerment' },
  widow: { text: 'Widow' },
  acid_victims: { text: 'Acid Attack Victims'},
  insurance: { text: 'Healthfirst' },
  sip_protect: { text: 'SIP Protect' }
};

export const SipStatus = {
  0: { text: 'New' },
  1: { text: 'Active' },
  2: { text: 'Lapsed' },
  3: { text: 'New' },
  4: { text: 'Cancelled' },
  5: { text: 'Cancel Requested' }
}