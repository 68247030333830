import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../util/util.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FundraiserService {

  public APIURL = environment.APP.BASE_URL;
  public CORE_API = environment.APP.CORE_API;
  public httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public httpOptionsHideError = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: {
      showError: 'false'
    }
  };

  constructor(
    public http: HttpClient,
    public utilService: UtilService,
    private api: ApiService
  ) { }

  /**
   * Get the details of fundraiser
   * @param customTag Custom Tag URL
   * @author Khizar Syed
   */

  getCampaign(customTag, qParam, showError?) {
    this.utilService.isToken = true;
    const url = environment.APP.API_URLS.GET_FUNDRAISER(customTag);
    return this.api.get(url + qParam, showError);
  }

  getCampaignViaID(id, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGN(id);
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the list of the donors
   * @param customTag Custom Tag URL
   */
  getDonors(customTag, qParam) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {
        showError: 'false'
      }
    };
    const url = this.APIURL + environment.APP.API_URLS.GET_DONORS(customTag);
    return this.http.get(url + qParam, options);
  }
  /**
   * Search anything in fundraiser with query params
   * @param customTag Custom Tag
   * @param search Search Query Params
   */
  searchFundraiser(customTag, search) {
    const url = this.APIURL + environment.APP.API_URLS.GET_FUNDRAISER(customTag);
    return this.http.get(url + search, this.httpOptions);
  }
  /**
   * Get The comments on the fundraiser
   * @param customTag Fundraiser Custom URL/Tag
   * @param qParam Search Query Params
   */
  getFundraiserComments(customTag, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_FUNDRAISER_COMMENTS(customTag);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the list of the donors
   * from social media sites
   * @param campaignId Campaign ID
   * @param qParam Query Params
   */
  getSocailDonors(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_FUNDRAISER_SOCIAL_SHARE(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the similar fundraisers
   * of given fundraiser id
   * @param campaignId Campaign ID
   * @param qParam Query Params
   */
  getSimilarFundraisers(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_SIMILAR_FUNDRAISER(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the updates of fundraisers
   * of given fundraiser id or customTag
   * @param customTag Custom Tag
   * @param qParam Query Params
   */
  getFundraiserUpdates(customTag, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_FUNDRAISER_UPDATES(customTag);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Get the updates of fundraisers
   * of given fundraiser id or customTag
   * @param url cmplete API Url
   */
  getPreviousUpdates(url) {
    return this.http.get(url, this.httpOptions);
  }

  /**Get fundraiser summary (supporters, raised) */
  getFundraiserSummary(customTag, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_FUNDRAISER(customTag);
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the donor card details
   * it gives the details if logged in user
   * already donated on this campaign
   * @param campaignId Campaign id
   * @param qParam Query Params
   */
  getDonorCardDetails(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_DONOR_CARD(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Its gives the donor list of the user' friends
   * who donated on the campaign
   * @param campaignId Campaign id
   * @param qParam Query Params
   */
  getSharyCardDetails(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_SHARY_CARD(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Ask for the update of the campaign API
   * @param campaignId Campaign ID
   * @param data Comment data
   */
  askForUpdate(campaignId, data) {
    const url = this.APIURL + environment.APP.API_URLS.ASK_FOR_UPDATE(campaignId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Post the comment on
   * perticular update of the campaign
   * @param campaignId Campaign ID
   * @param updateId Update Id
   * @param data Comment data
   */
  postCommentOnUpdate(campaignId, updateId, data) {
    const url = this.APIURL + environment.APP.API_URLS.POST_COMMENTS_UPDATE(campaignId, updateId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Post the comment on the campaign
   * @param campaignId Campaign ID
   * @param data Comment data
   */
  postCommentOnCampaign(campaignId, data) {
    const url = this.APIURL + environment.APP.API_URLS.POST_COMMENTS_CAMPAIGN(campaignId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Get the number of whatsapp shares for the campaign
   */
  getWhatsappShares() {
    return Math.floor(Math.random() * 100) + 700;
  }
  /**
   * Get the total number of fb shares of
   * perticular campaign
   * @param data Campaign URL
   */
  getFacebookShares(data) {
    let url = 'https://graph.facebook.com/v3.2/?id=';
    if (this.utilService.domain_details) {
      url = url.concat(this.utilService.domain_details.domain_url + data);
    }
    return this.http.get(url);
  }
  /**
   * Get the profile picture of the fb logged in user
   * @param fbId User id of the facebook user
   */
  getFbProfilePic(fbId) {
    const url = `http://graph.facebook.com/${fbId}/picture?type=square`;
    return this.http.get(url, this.httpOptions);
  }
  /**
   * API to log a new like for updates
   * @param campaignId Campaign ID of the fundraiser
   * @param updateId Update ID of the fundraiser
   */
  postUpdateLikes(campaignId, updateId, data) {
    const url = this.APIURL + environment.APP.API_URLS.POST_UPDATE_LIKE(campaignId, updateId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Contact the campainger API
   * @param data Message Info
   */
  contactCampaigner(data) {
    const url = this.APIURL + environment.APP.API_URLS.CONTACT_CAMPAINGER(data.campaign_id);
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.post(url, data, options);
  }
  /**
   * Get the different details for
   * microsites
   * @param domainName Domain name of microsite
   * @param qParam Data requirement
   */
  getHeader(domainName, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_HEADER(domainName);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Update the status of the user
   * Whether user wants to make donation anonymously
   * @param data Data Object
   * @param entityId User Id
   * @param orderId Order Id or Payment Id
   */
  updateAnonymous(data, entityId, orderId) {
    const url = this.APIURL + environment.APP.API_URLS.UPDATE_ANONYMOUS(entityId, orderId);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Generate the links for UPI Payment apps\
   * @param data Data required
   */
  generateUPILinks(data) {
    const url = this.CORE_API + environment.APP.API_URLS.GENERATE_UPI_LINKS;
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      }),
      params: data
    };
    return this.http.get(url, options);
  }
  /**
   * Generate the links for UPI Payment apps using Razor pay
   * @param data Data required
   */
  generateUPILinksRazor(data, id) {
    this.utilService.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.GENERATE_UPI_LINKS_RAZOR(id);
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      }),
      params: data
    };
    return this.http.get(url, options);
  }
  /**
   * Upload Profile Pic
   * @param userId Upload Profile Pic
   * @param data file data
   */
  uploadProfilePic(userId, data) {
    const url = this.APIURL + environment.APP.API_URLS.UPLOAD_PROFILE_PIC(userId);
    const fd = new FormData();
    fd.append('file', data);
    const options = {
      headers: new HttpHeaders(),
    };
    return this.http.post(url, fd, options);
  }
  /**
   * Contribute button core api
   * @param data Required to for contribute
   */
  contributeNow(data) {
    const url = this.CORE_API + environment.APP.API_URLS.CONTRIBUTE_NOW;
    const options = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      }),
      params: data
    };
    return this.http.post(url, data, options);
  }
  /**
   * Get the details of the perticular order generated for
   * perticular campaign by providing following details
   * @param campaignId Campaign Id
   * @param orderId Order Id
   * @param qParam Query params inputs
   */
  getOrderDetails(campaignId, orderId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_ORDER_DETIALS(campaignId, orderId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Submit the acknowledge form to get the transaction reciept
   * of the donation
   * @param data form data
   */
  submitAcknowledgeForm(data) {
    const url = this.APIURL + environment.APP.API_URLS.SUBMIT_ACKNOWLEDGE_FROM;
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }

  /**
   * Get the list of the supporting fundraiser running
   * under the any of the parent fundraiser, which required the id
   * of that fundraiser
   * @param campaignId Campaign Id
   * @param qParam Query params inputs
   */
  getSupportingFrList(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_SUPPORTING_FRUNDRAISERS(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }
  /**
   * Start the supporting fundraiser
   * @param campaignId Campaign Id
   * @param data Required data
   */
  startSupprtingFr(campaignId, data) {
    const url = this.APIURL + environment.APP.API_URLS.START_SUPPORTING_FRUNDRAISERS(campaignId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Edit the existing child/supporting fundraiser data
   * @param campaignId Campaign Id
   * @param supportingFrId Child/Supportig frundraiser id
   * @param data Required data
   */
  editSupportingFr(campaignId, supportingFrId, data) {
    const url = this.APIURL + environment.APP.API_URLS.EDIT_SUPPORTING_FRUNDRAISERS(campaignId, supportingFrId);
    return this.http.put(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Check if user already created the support fundraiser
   * @param campaignId Campaign Id
   * @param qParam Query params inputs
   */
  checkIfUserHasSupportFr(campaignId, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.CHECK_IF_SUPPORT_FR_CREATED(campaignId);
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get all the urgent story page campiagns
   * @param qParam Query params inputs
   */
  getUrgentStoryCampaigns(qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGNS;
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the perticular event by passign event id
   * @param qParam Query params inputs
   */
  getEvent(id, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_EVENT(id);
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the perticular event causes by passign event id
   * @param qParam Query params inputs
   */
  getEventCauses(id, qParam) {
    const url = this.APIURL + environment.APP.API_URLS.GET_EVENT_CAUSES(id);
    return this.http.get(url + qParam, this.httpOptions);
  }

  /**
   * Get the count of the campaign
   * @author Suhel Khan
   */
  getCampaignCount() {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPIGN_COUNT;
    return this.http.get(url, this.httpOptions);
  }

  /**
   * Post Campaign Visit of current user
   * @author Suhel Khan
   */
  campaignVisitTrack(campaignId, userId, data) {
    const url = this.APIURL + environment.APP.API_URLS.CAMPAIGN_VISIT(campaignId, userId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }

  verifyPrivateFundraiserPIN(campaignId, pin) {
    const postData = { pin };
    const url = this.APIURL + environment.APP.API_URLS.VERIFY_PRIVATE_FUNDRAISER_PIN(campaignId);
    return this.http.post(url, postData, this.httpOptions);
  }

  /** Get campaign from Meilisearch */
  getCampaignsMeilisearch(qParam) {
    this.utilService.isToken = true;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${environment.meilisearch.key}`
      }),
      params: qParam
    };
    const url = `${environment.meilisearch.host}/indexes/${environment.meilisearch.index}/search`;
    return this.http.get(url, options);
  }

  getCampaignDetails(campaignId, queryParams) {
    const url = this.APIURL + environment.APP.API_URLS.GET_CAMPAIGN_DETAILS(campaignId);
    return this.http.get(url+ queryParams);
  }
}
