import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SipTopBannerComponent } from './components/sip-top-banner/sip-top-banner.component';

@NgModule({
  declarations: [SipTopBannerComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SipTopBannerComponent
  ]
})
export class SipTopBannerModule { }
