import { SipTopBannerModule } from './../common/sip-top-banner/sip-top-banner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { FooterSeoModule } from '../common/footer-seo/footer-seo.module';
import { AbandonedCartModule } from '../common/abandoned-cart/abandoned-cart.module';
import { ZohoChatModule } from '../common/zoho-chat/zoho-chat.module';
import { VerloopChatModule } from '../common/verloop-chat/verloop-chat.module';
import { ModuleLoaderModule } from '../common/module-loader/module-loader.module';
import { HospitalFooterSeoComponent } from '../common/hospital-footer-seo/hospital-footer-seo.component';
import { HomeV2DockComponent } from '../common/home-v2-dock/home-v2-dock.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FooterSeoModule,
    ZohoChatModule,
    AbandonedCartModule,
    SipTopBannerModule,
    VerloopChatModule,
    ModuleLoaderModule,
    HospitalFooterSeoComponent,
    HomeV2DockComponent
  ],
  declarations: [MainLayoutComponent],
  exports: [MainLayoutComponent],
})
export class LayoutsModule { }
