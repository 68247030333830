export const FOOTER_SEO_CONTENT_SPORTS = {
  mainTitle: 'Start Your Online Sports Crowdfunding Campaign With Ketto',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'Ketto Sports Crowdfunding',
      desc: `We at Ketto understand you and your team require funds for equipment, travel, training, competition expenses, and other expenses to be successful. Raising funds in the traditional way of asking people face to face does not seem correct and is extremely time-consuming.`,
      children: null
    },
    {
      id: 2,
      heading: ' Here is how Ketto can help you raise funds for sports.',
      subHeading: '',
      desc: `<p class="desc">When the one you love needs treatment, medical expenses are an added stress. This, along with treatments, can continue for a long time causing medical bills and medical expenses to keep piling up for the family.</p>
            <p class="desc">Using a dedicated <a href="https://www.ketto.org/new/crowdfunding">fundraising page</a>  and broad reach on social media with a strong online presence, Ketto uses <a href="https://www.ketto.org/blog/role-of-social-media-in-online-fundraising">social awareness</a> and technology to enable athletes to bring together this community to achieve the fundraising goal for sports crowdfunding campaigns efficiently.
            </p>`,
      children: null
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Below are a few ideas of what various campaigns athletes, coaches, and friends can launch with Ketto:',
      desc: ``,
      children: [
        {
          id: 1,
          heading: '',
          desc: '',
          bullet: true,
          list: [
            {
              id: 1,
              title: 'Travelling Costs',
              desc: ''
            },
            {
              id: 2,
              title: 'Training Costs',
              desc: ''
            },
            {
              id: 3,
              title: 'Events Participation costs',
              desc: ''
            },
            {
              id: 4,
              title: 'Equipment Costs',
              desc: ''
            },
            {
              id: 5,
              title: 'Sports Injury Rehabilitation',
              desc: ''
            },
            {
              id: 5,
              title: 'Sports-related Medical Costs',
              desc: ''
            },
            {
              id: 6,
              title: 'Sports & Athlete Causes',
              desc: ''
            },
            {
              id: 7,
              title: 'Collect Team Fees',
              desc: ''
            },
            {
              id: 8,
              title: 'School/College/University Sports Expenses',
              desc: ''
            },
            {
              id: 9,
              title: 'Purchase of Sports Merchandise',
              desc: ''
            },
            {
              id: 10,
              title: 'Nonprofit and Charitable Sports Event Organising',
              desc: ''
            }
          ]
        }
      ]
    },
    {
      id: 4,
      heading: 'Every successful sports campaign should have the following in its campaign:',
      subHeading: '',
      desc: ``,
      children: [
        {
          id: 1,
          heading: '',
          desc: '',
          bullet: true,
          list: [
            {
              id: 1,
              title: 'An eye-catching cover photo that shows the athlete in action or the sports team together.',
              desc: ''
            },
            {
              id: 2,
              title: 'A message story clearly explaining the campaign goal, previous experiences, and other important information.',
              desc: ''
            },
            {
              id: 3,
              title: 'Timely updates given to contributors and followers, informing them about progress, results, and events along with the campaign.',
              desc: ''
            }
          ]
        }
      ]
    },
    {
      id: 5,
      heading: '',
      subHeading: 'So are you ready to start a successful online sports crowdfunding campaign on Ketto?',
      desc: ``,
      children: null
    },
    {
      id: 6,
      heading: '',
      subHeading: 'Let\'s get into the details:',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'How to choose your campaign cover picture',
          desc: `<p class="desc">People judge a book by its cover. However, in reality, our reading choices are based on specific aesthetics. The same applies to online sports crowdfunding, and fundraising campaigns started on Ketto!</p>
                    <p class="desc">To get a donor's attention, a stunning in-game action shot or a team picture will be perfect</p>`,
          bullet: true,
          list: [
            {
              id: 1,
              title: 'The picture should be of High-quality and high resolution & Attention-grabbing.',
              desc: ''
            }
          ]
        },
        {
          id: 2,
          heading: 'How to write a compelling Sports Crowdfunding title',
          desc: `Make the first impression. To achieve this, you must ensure the title is exciting and aligns perfectly with your campaign goal. <br>
                    Donors want to be a difference by helping you in your life and achievements.<br>
                    While writing your title, you should keep in mind three Cs – Clear, Concise & Catchy. <br>
                    <strong>Clear:</strong> What is the ultimate goal of your sports fundraising campaign?<br>
                    <strong>Concise:</strong> Your contributors should be able to explain to others your campaign in one short phrase.<br>
                    <strong>Catchy:</strong> Donors will share your sports funding campaign, be creative. A creative campaign is easy to remember.`,
          list: null
        },
        {
          id: 3,
          heading: 'Written story',
          desc: `Elaborate on points, provide information about your past, present, and future, why you initiated this campaign. <br>
                    Your text should contain:`,
          bullet: true,
          list: [
            {
              id: 1,
              title: 'A captivating and emotional message. It should plant a seed of giving and make people root for your success!',
              desc: ''
            },
            {
              id: 2,
              title: 'Point your athletic goals clearly, provide an overview of how far you’ve come, and explain your commitment to the sport!',
              desc: ''
            },
            {
              id: 3,
              title: 'Be transparent to the donors. They should trust you and feel they are supporting an honest cause.',
              desc: ''
            },
            {
              id: 4,
              title: 'Let the donors know the breakdown of exactly what the funds will be used for.',
              desc: ''
            },
            {
              id: 5,
              title: 'Thank the donors and visitors to your campaign. A positive and powerful thank you message is never forgotten.',
              desc: ''
            }
          ]
        }
      ]
    },
    {
      id: 7,
      heading: '',
      subHeading: '',
      desc: `If you want to learn more about Sports Crowdfunding & Sports Fundraising visit Ketto.org or read our <a href="https://www.ketto.org/blog">blogs</a> for unique and informative content.`,
      children: null
    }
  ],
  searches: null
};
