import { DOCUMENT } from '@angular/common';
import { UtilService } from './../../services/util/util.service';
import { VariablesService } from './../../services/variables/variables.service';
import { EventsService } from './../../services/events/events.service';
import { Component, OnInit, Inject } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
  animations: [trigger('bounce', [transition('* => *', useAnimation(bounce))])],
})
export class PagenotfoundComponent implements OnInit {
  previousPageURL;
  pageContent = {
    title: 'This page does not exist.',
    mainTitle: 'But your heart is in the right place!',
    desc: 'Tap below to find out how your help can transform someone’s life.',
    links: [
      { id: 1, title: 'Medical Cause', link: 'https://www.ketto.org/cause/medical-fundraising?utm_source=404Page&utm_medium=404CTA&utm_campaign=404Tracking' },
      { id: 2, title: 'Education Cause', link: 'https://www.ketto.org/cause/fundraising-for-education?utm_source=404Page&utm_medium=404CTA&utm_campaign=404Tracking' },
      { id: 3, title: 'Cancer Funding', link: 'https://www.ketto.org/cause/cancer-crowdfunding?utm_source=404Page&utm_medium=404CTA&utm_campaign=404Tracking' },
      { id: 4, title: 'Social Impact Plan', link: 'https://www.ketto.org/new/sip?utm_source=404Page&utm_term=404CTA&utm_campaign=404Tracking' },
    ]
  };

  constructor(
    private router: Router,
    private eventService: EventsService,
    private variablesService: VariablesService,
    private utilService: UtilService,
    private actRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document
  ) { }

  ngOnInit() {
    this.variablesService.mainLayoutConfig.next({
      showFooter: true,
      showMenus: true,
      showProfile: true,
      showSearch: true,
      logoNavigate: true,
      showHeader: true,
      headerTopSticky: false
    });
    this.sendSystemEvents('pageView');
    this.utilService.addCssToGlobal('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  }

  sendSystemEvents(choice, otherInfo_1?): void {
    const choices = {
      pageView: {
        eventName: 'pageview',
        page_name: '404'
      },
      other: {
        eventName: '404links',
        page_name: '404',
        info_1: otherInfo_1 ? otherInfo_1.toLowerCase() : '',
        info_2: '',
        referrer_page: this.document.referrer,
      },
    };
    const payload = choices[choice];
    this.eventService.onLoadSystemEvent(payload);

  }

}
