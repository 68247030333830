import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterSeoComponent } from './components/footer-seo/footer-seo.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [FooterSeoComponent],
  imports: [
    CommonModule,
    MatExpansionModule
  ],
  exports: [FooterSeoComponent]
})
export class FooterSeoModule { }
