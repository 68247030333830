import { ApiService } from './../api/api.service';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IBasicInfo, IFundraiser } from '../../../fundraiser/models/fundraiser.model';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';
import { IUTM } from '../../models/utm.model';
import { CookieService } from '../cookie/cookie.service';
import { VariablesService } from '../variables/variables.service';
import { EventsService } from '../events/events.service';
import { IUser } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { FbService } from '../fb/fb.service';
import { FacebookService } from 'ngx-facebook';

@Injectable({
  providedIn: 'root'
})
export class ShareMessagesService {

  isLoggedIn;
  userdata: IUser;
  constructor(
    @Inject(DOCUMENT) private document: any,
    public storageService: StorageService,
    public utilService: UtilService,
    public cookieService: CookieService,
    public variablesService: VariablesService,
    public eventsService: EventsService,
    private actRout: ActivatedRoute,
    private apiService: ApiService,
    private fbService: FbService,
    public fb: FacebookService
  ) {
    this.utilService.isLoggedIn.subscribe({ next: res => this.isLoggedIn = res });
    this.variablesService.userData.subscribe({ next: res => this.userdata = res });
  }

  /**
 * Get The Whatsapp message
 * @param fundraiser Fundraiser Object
 */
  getFacebookUtm(fundraiser: IFundraiser) {
    return `?utm_source=internal&utm_medium=fbpageshare&utm_campaign=${fundraiser.custom_tag}` + this.getUtmContent();
  }

  /**
   * Get The Whatsapp message
   * @param fundraiser Fundraiser Object
   * @param basicInfo Basic Info of fundraiser
   * @param fStatus Fundraiser ENded Status
   */
  async getWhatsAppMessage(fundraiser: IFundraiser, basicInfo: IBasicInfo, fStatus: boolean, utms?, useLongUrl = false) {
    let pageName = 'fundraiser';
    if (this.variablesService.fundraiser.support_campaign) {
      pageName = 'supportfundraiser';
    }
    const page = this.variablesService.campaignType === 'stories' ? 'stories' : pageName;
    const campaignUrl = `${this.utilService.domain_details.domain_url}/${page}/${fundraiser.custom_tag}`;
    // const shortURL = await this.apiService.getShortURL(`rm-${fundraiser.id}`, campaignUrl);
    // console.log(shortURL);
    const host = this.utilService.domain_details.domain_url;
    const about_html = this.document.createElement('div');
    const utmData = {
      source: utms?.utm_source||'internal',
      medium: utms?.utm_medium || 'whatsappShare',
      term: utms?.utm_term || 'whatsappShare',
      campaign_name: fundraiser.custom_tag
    };
    const payload = {
      id: `rm-${fundraiser.id}`,
      long_url: this.getLinkWithUtmTags(campaignUrl, utmData),
      utm_content: this.userdata?.id ? this.userdata?.id : ''
    }
    let shortOrLongURL;
    if (!useLongUrl) {
      shortOrLongURL = await this.apiService.getShortURL(payload);
    } else {
      shortOrLongURL = this.getLinkWithUtmTags(campaignUrl, utmData);
    }
    about_html.innerHTML = this.variablesService.basicInfo.social;
    let about_text = about_html.textContent;
    about_text = about_text.replace(/\s\s+/g, ' ');
    if (about_text.length > 441) {
      about_text = `*${this.utilService.capitalizeFirstLatter(fundraiser.title)}* \n\n` + about_text.substr(0, 441).concat('...');
    }
    // If fundraiser ended then no contibute link
    let campainerName = '';

    if (fundraiser.campaigner && fundraiser.campaigner.full_name) {
      campainerName = fundraiser.campaigner.full_name.trim();
    } else {
      campainerName = 'Campaigner';
    }

    // Campaing Web URL
    let web_link = `\n\n*Read More.* ${shortOrLongURL}\n\n`;

    if (!fStatus) {
      // Campaing Web URL
      web_link = `\n\n*Read More & Donate Now.* ${shortOrLongURL}\nEven a small amount will make a difference.\n\n`;
    }

    // `${host}/contribute/contribute.php${this.getLinkWithUtmTags('', utmData)}&fmd_id=${fundraiser.id}`;
    // Finally a cooked text ready for whatsapp
    const text_to_share = about_text + web_link;

    return encodeURIComponent(text_to_share);
  }
  /**Whatsapp share from sac thank you page */
  getWhatsAppMessage3(fundraiser) {
    let pageName = 'fundraiser';
    if (this.variablesService.fundraiser.support_campaign) {
      pageName = 'supportfundraiser';
    }
    const campaignUrl = `${this.utilService.domain_details.domain_url}/${pageName}/${fundraiser.custom_tag}`;
    const utmData = {
      source: 'internal',
      medium: 'whatsappShare',
      campaign_name: this.variablesService.fundraiser.custom_tag,
    };
    let beneficiaryRelation = '';
    if (fundraiser.beneficiaryrelation && fundraiser.beneficiaryrelation.info_1) {
      beneficiaryRelation = 'for my ' + fundraiser.beneficiaryrelation.info_1;
    }
    const text_to_share = `Hi,\n\n*I have started a fundraiser on Ketto* ${beneficiaryRelation}`
      .concat(`\n\nI am sharing it with the *people closest* to me and *you* are amongst them 😇`)
      .concat(`\n\nIt is *essential that I receive support, no matter how small it is*, to keep it going`)
      .concat(`\n\n${this.getLinkWithUtmTags(campaignUrl, utmData)}&utm_placement=sac_thank_you`)
      .concat(`\n\nThanks for your support in advance 🙏🏼`);
    return encodeURIComponent(text_to_share);
  }

  getWhatsAppMessageForDream11() {
    const campaignUrl = `${this.utilService.domain_details.domain_url}/fundriaser/${this.variablesService.fundraiser.custom_tag}`;
    const utmData = {
      source: 'internal',
      medium: 'whatsappShare',
      campaign_name: this.variablesService.fundraiser.custom_tag
    };
    const message = `Come join the team and help us fight against COVID. \n\n${this.getLinkWithUtmTags(campaignUrl, utmData)}`;
    this.shareEvents('whatsapp share', 36);
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  }

  getUtmContent() {
    const data = this.utilService.getUser()?.user;
    const userId = this.utilService.getUserData()?.id;
    const _ku = this.cookieService.getCookie('_ku');
    if (data) {
      return `&utm_content=${userId}`;
    } else if (_ku) {
      return `&utm_content=${_ku}`;
    } else {
      return '';
    }
  }
  /**Get the UTMS for the link */
  getLinkWithUtmTags(link: string, data: IUTM) {
    let tags = `?utm_medium=${data.medium}${this.getUtmContent()}&shby=1&utm_term=${data?.term || ''}`;
    const prviousUtms = this.utilService.getUTMs();
    tags = tags.concat(prviousUtms.hasOwnProperty('utm_source') ? `&utm_source=${prviousUtms.utm_source}` : `&utm_source=${data.source}`);
    if (data.campaign_name) {
      tags = tags.concat(`&utm_campaign=${data.campaign_name}`);
    }
    if (this.actRout.snapshot.queryParams['update_id']) {
      tags = tags.concat(`&update_id=${this.actRout.snapshot.queryParams['update_id']}`);
    }
    return link ? link + tags : tags;
  }

  /**Event fired after share */
  shareEvents(eventName, postion) {
    const systemEvent = {
      eventName: eventName,
      event_type: 'campaign',
      event_type_id: this.variablesService?.fundraiser?.id,
      info_1: 'position',
      info_2: postion || 3,
      info_3: this.variablesService.campaignType,
      page_name: this.variablesService.pageName
    };
    this.eventsService.sendSystemEvent(systemEvent).subscribe(_ => _);
    const claverTap = {
      'Campaign Id': this.variablesService.fundraiser.id,
      'Page URL': this.utilService.domain_details.full_url,
      'Campaign Title': this.variablesService.fundraiser.title,
      'Browser': this.utilService.detectBrowser(),
      'Campaign Type': this.utilService.getCampaignTypeFromId(this.variablesService.fundraiser.id),
      'Cause ID': this.variablesService.fundraiser.cause_id,
      'Device Type': this.utilService.isMobile() ? 'Mobile' : 'Desktop'
    };
    this.eventsService.claverTapPush(eventName, claverTap);
    const gtmData = {
      'event': 'social_shares',
      'event_category': eventName,
      'event_action': `social share ${eventName}`,
      'event_label': this.getEntity(), // campaigner or visitor or ngo
      'Campaign_custom_tag': this.variablesService.fundraiser.custom_tag || '',
      'Campaign_cause_id': this.variablesService.fundraiser.cause_id || '',
      'Campaign_Type': this.utilService.getCampaignTypeFromId(this.variablesService.fundraiser.id) || '',
      'Page_version': '',
      'position': postion || 3
    };
    this.eventsService.gtmPush(gtmData);
  }
  getEntity() {
    if (this.isLoggedIn) {
      this.utilService.getUserType(
        this.variablesService.fundraiser.creator_entity_details_id,
        this.variablesService.fundraiser.entity_details_id,
        this.userdata?.id
      );
    }
  }
}
