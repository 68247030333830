import { FOOTER_SEO_CONTENT_SPORTS } from './objects/footer-seo-sports';
import { FOOTER_SEO_CONTENT_TRANSPLANT } from './objects/footer-seo-transplant';
import { FOOTER_SEO_CONTENT_ANIMAL_FUNDRAISING } from './objects/footer-seo-animal-fundraising';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService, VariablesService } from '../../../../shared/services';
import { FOOTER_SEO_CONTENT_CANCERCROWDFUNDING } from './objects/footer-seo-cancer-crowdfunding';
import { FOOTER_SEO_CONTENT_CHILD_HELP } from './objects/footer-seo-child-help';
import { FOOTER_SEO_CONTENT_FUNDRAISING_FOR_EDUCATION } from './objects/footer-seo-fundraising-for-education';
import { FOOTER_SEO_CONTENT_HOME } from './objects/footer-seo-home';
import { FOOTER_SEO_CONTENT_HOW_IT_WORKS } from './objects/footer-seo-how-it-works';
import { FOOTER_SEO_CONTENT_MEDICAL_FUNDRAISING } from './objects/footer-seo-medical-fundraising';
import { FOOTER_SEO_CONTENT_MEMORIAL } from './objects/footer-seo-memorial';
import { IMainLayoutConfig } from 'src/app/shared/models/main-layout.config';

@Component({
  selector: 'app-footer-seo',
  templateUrl: './footer-seo.component.html',
  styleUrls: ['./footer-seo.component.scss'],
})
export class FooterSeoComponent implements OnInit, OnDestroy {

  footerSeoContent = null;
  addMarginBottom = false;
  addMarginTop = false;
  loggedinSubs: Subscription;
  @ViewChild('mapanel') mapanel: MatExpansionPanel;
  constructor(
    public utilService: UtilService,
    private router: Router,
    public vars: VariablesService
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = this.router.url.split('?')[0];
          this.checkLoggedIn();
          this.getFooterSEO();
          this.addMarginTop = this.isCausePage() && this.url !== '/' && this.url !== '/crowdfunding';
          // to close opened footer expansion panel
          this.mapanel?.close();
        }
      });
  }
  url;
  whatsappWidgetSpace;
  mainLayoutConfig: IMainLayoutConfig;
  isMobile = false;
  subs = [];
  isHomeV2 = false;

  isCausePage(): boolean {
    return this.url.match('cause');
  }

  ngOnInit() {
    this.url = this.router.url.split('?')[0];
    this.addMarginTop = this.url.match('cause') && this.url !== '/' && this.url !== '/crowdfunding';
    this.whatsappWidgetSpace = this.url !== '/crowdfunding' && this.url !== '/cause/memorial-fundraising';
    this.checkLoggedIn();
    this.getFooterSEO();
    this.vars.mainLayoutConfig.asObservable().subscribe(val => {
      this.mainLayoutConfig = val;
    })
    this.isMobile = this.utilService.isMobile();
    this.subs.push(this.vars.homeV2.subscribe(val => {
      this.isHomeV2 = val;
      if (this.url === '/' && this.isHomeV2) {
        this.addMarginBottom = false;
        this.whatsappWidgetSpace = this.isMobile;
      }
    }))
  }

  getFooterSEO(): void {
    const contents = {
      '/': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_HOME;
      },
      '/crowdfunding': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_HOW_IT_WORKS;
      },
      '/cause/medical-fundraising': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_MEDICAL_FUNDRAISING;
      },
      '/cause/cancer-crowdfunding': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_CANCERCROWDFUNDING;
      },
      '/cause/fundraising-for-education': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_FUNDRAISING_FOR_EDUCATION;
      },
      '/cause/memorial-fundraising': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_MEMORIAL;
      },
      '/cause/help-children': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_CHILD_HELP;
      },
      '/cause/animal-fundraising': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_ANIMAL_FUNDRAISING;
      },
      '/cause/transplant-crowdfunding': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_TRANSPLANT;
      },
      '/cause/sports-crowdfunding': () => {
        this.footerSeoContent = FOOTER_SEO_CONTENT_SPORTS;
      }
    };
    if (Object.keys(contents).includes(this.url)) {
      contents[this.url]();
    }
  }

  checkLoggedIn() {
    /**
     * login check added because startfundraiser widget on bottom comes when user is logged in
     * if user not logged in then no use of adding margin bottom
     */
    this.loggedinSubs = this.utilService.isLoggedIn.subscribe(
      (data) => {
        if (!data) {
          return;
        }
        // not logged in and url is homepage so add margin
        // this.addMarginBottom = !data && this.url === '/';
        if (this.url === '/crowdfunding') {
          this.addMarginBottom = data;
        }
        if (!data && this.url === '/' || !data && this.url === '/crowdfunding') {
          this.addMarginBottom = true;
        }
        this.vars.draftData.subscribe((res) => {
          if (res) {
            this.addMarginBottom = false;
          }
        });
        if (this.url === '/' && this.isHomeV2) {
          this.addMarginBottom = false;
        }
      }
    );
  }

  scrollDown() {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight - (document.getElementById('footer-seo').clientHeight),
          behavior: 'smooth'
        });
      }, 200);
    }
  }

  isNumericFlow() {
    return (this.url === '/' ||
      this.url === '/cause/fundraising-for-education' ||
      this.url === '/crowdfunding' ||
      this.url === '/cause/memorial-fundraising' ||
      this.url === '/cause/animal-fundraising' ||
      this.url === '/cause/transplant-crowdfunding' ||
      this.url === '/cause/sports-crowdfunding');
  }
  ngOnDestroy() {
    if (this.loggedinSubs) {
      this.loggedinSubs.unsubscribe();
    }
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
