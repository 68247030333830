export const FOOTER_SEO_CONTENT_FUNDRAISING_FOR_EDUCATION = {
  mainTitle: 'Education Fundraising is a Step Towards a Brighter Future. Click Here to Know More',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'History Of Education Crowdfunding',
      desc: `The term “Crowdfunding” in India is commonly known as ‘Chanda’. Education Crowdfunding is deep-rooted within the core of our culture. It is rapidly evolving and has transformed into a solution for students that put higher education prospects on hold due to the lack of monetary funds.`,
      children: null
    },
    {
      id: 2,
      heading: '',
      subHeading: 'Why Does India Need Education Fundraising?',
      desc: 'The Constitution of India provides children from the age of 6 through 14 the Right To Education. However, this right alone isn’t enough. Crowdfunding offers access to affordable and excellent quality education to children who desire to pursue higher education.',
      children: [
        {
          id: 1,
          heading: 'For Which Education Causes Can We Raise Funds?',
          desc: 'Families, seniors, juniors, faculty(teaching & non-teaching), and <a href="https://www.ketto.org/blog/crowdfunding-for-teachers-how-to-raise-money-for-your-classroom"> school or college staff can start crowdfunding campaigns </a> for:',
          desc2: `Now you, too, can Start Your Free Education-related Fundraiser Today and Raise Funds for Your School, College, or University!`,
          list: [
            {
              id: 1,
              title: 'SCHOOLS-PRIVATE & GOVERNMENT',
              desc: 'Private and government schools and universities require extra funds to support exhibitions or academic work.'
            },
            {
              id: 2,
              title: 'EDUCATIONAL INSTITUTION CLUBS',
              desc: 'Book clubs, student societies, and school clubs often need to raise money to fund their respective undertakings.'
            },
            {
              id: 3,
              title: 'AFTER-SCHOOL AND EXTRACURRICULAR PROGRAMS',
              desc: 'Many academic institutions encourage after-school programs that serve to nurture a healthy and academically rich environment for learners.'
            },
            {
              id: 4,
              title: 'DAYCARE FACILITIES',
              desc: 'Daycares provide an excellent way for parents to ensure their kids are within a safe space to play and learn.'
            }
          ]
        },
        {
          id: 2,
          heading: 'Which Other Fundraising Ideas Team Perfectly For School Crowdfunding Campaigns?',
          desc: `You can pair the following <a href="https://www.ketto.org/blog/five-quick-fundraising-ideas"> ideas</a> with your campaigns to generate more funds towards your education-related fundraiser:`,
          list: [
            {
              id: 1,
              title: 'GIFTING ',
              desc: `You can increase the influence of your <a href="https://www.ketto.org/blog/education-crowdfunding-the-best-way-to-help-to-achieve-their-dreams">education fundraising</a> campaign by exploring student-parent gifting eligibility.`
            },
            {
              id: 2,
              title: 'T-SHIRT FUNDRAISER',
              desc: `T-shirts sell! Everyone loves a comfy T-shirt! Creating a T-shirt that pairs well with the school motto will ensure all can wear it to school and have something cool to boast about. <br> Buying these in bulk reduces the cost considerably. It, in turn, increases the profits you can make on selling it. You can also coordinate with a delivery company to send a t-shirt to donors on your education crowdfunding campaign.`
            },
            {
              id: 3,
              title: 'PARTNER WITH A RESTAURANT FRANCHISE',
              desc: `Countless restaurant franchises are willing to collaborate with schools and educational organisations to support them and raise money. Many restaurant franchises have pre-made handouts and stickers for students, faculty, staff, and parents to distribute to friends and family members and promote the fundraiser.`
            },
            {
              id: 4,
              title: '(PTA) PARENT-TEACHER ASSOCIATION PARTY',
              desc: 'Parents need a break too. A PTA party will increase the communication between parents and school staff. A PTA Party is a fantastic opportunity to raise money for your school! <br> Charge a small price and organise a fun night of food and drinks for parents. Volunteers can look after children, so the parents don’t worry about hiring babysitters.',
            },
            {
              id: 5,
              title: 'BUY-A-BRICK IS ONE GREAT WAY OF GENERATING FUNDS',
              desc: 'Many institutions plan to expand the structure to create more facilities like gymnasiums, indoor sports halls, and swimming pools. You can organise a Buy-A-Brick sale that generates funds for these expansion plans. <br> This fundraiser is a good example and will make each donor feel connected to the cause! This will also allow the alumni to give back to the institution that made them who they are today.',
            },
            {
              id: 6,
              title: 'CULTURAL FAIRS',
              desc: 'An educational or cultural fair is an excellent way for school students to showcase their talent and cultures. It unifies the students and lets them connect better. <br> This event requires meticulous planning and provides schools with the opportunity to raise a considerable amount of funds while also educating students of different cultures and values.',
            },
          ]
        }
      ]
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Is Crowdfunding Helping The Education System In India?',
      desc: `Yes! Crowdfunding has ensured generous individuals in India can help those in need, stimulate talent amongst youth, extend positivity and kindness, and empower change. <br> Crowdfunding is presently playing a significant role in the online fundraising system. It is also the most effective means of raising funds for students, teachers, educational institutions and educational NGOs monetary needs. <br> Crowdfunding is a simple solution and empowers many individuals to raise funds and donate generously towards educational causes they care most about.`,
      children: [
        {
          id: 1,
          heading: 'What All Education-related Needs Can You Raise Funds For On Ketto?',
          desc: 'With Ketto Crowdfunding, you can raise funds to:',
          bullet: true,
          list: [
            {
              id: 1,
              title: 'Pay School Fees',
              desc: ''
            },
            {
              id: 2,
              title: 'Graduation And Post Graduation Education Cost',
              desc: ''
            },
            {
              id: 3,
              title: 'College Admission Fees',
              desc: ''
            },
            {
              id: 4,
              title: 'Cost Of Medical Education',
              desc: ''
            },
            {
              id: 5,
              title: 'Tuition Fee',
              desc: ''
            },
            {
              id: 6,
              title: 'Cost Of Commerce & Arts Education',
              desc: ''
            },
            {
              id: 7,
              title: 'Accommodation',
              desc: ''
            },
            {
              id: 8,
              title: 'Cost Of Engineering Education',
              desc: ''
            },
            {
              id: 9,
              title: 'Primary Education',
              desc: ''
            },
            {
              id: 10,
              title: 'Other Expenses Like Eating Out',
              desc: ''
            },
            {
              id: 11,
              title: 'Secondary Education',
              desc: ''
            },
            {
              id: 12,
              title: 'Public And Private Transport Costs.',
              desc: ''
            },
          ]
        }
      ]
    },
    {
      id: 4,
      heading: '',
      subHeading: 'What Does Ketto Provide?',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'PERSONALIZED WEB APP',
          desc: 'Receive instant updates of your fundraiser’s progress through instant alerts, email and track the campaign in real-time on Ketto’s web app.',
          list: null
        },
        {
          id: 2,
          heading: 'ADVERTISING SUPPORT',
          desc: 'Receive marketing and promotional support for your fundraiser.',
          list: null
        },
        {
          id: 3,
          heading: 'FUNDRAISING MARKETING TOOL',
          desc: 'An intelligent marketing tool, which provides all insights on your fundraiser.',
          list: null
        },
      ]
    },
    {
      id: 5,
      heading: '',
      subHeading: 'Start Your Education-related Crowdfunding Campaign Today To Raise Funds For Your School, College, Or University.?',
      desc: '',
      children: null
    },
    {
      id: 6,
      heading: '',
      subHeading: 'Get Started With Your Fundraising Campaign On Ketto',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'IT’S FAST; IT’S SIMPLE',
          desc: 'On Ketto, you can raise money for your school or education-related needs with personalised and unique crowdfunding campaigns. Your education campaign can be live and running in less than five minutes, and you can start accepting donations instantly.',
          list: null,
        },
        {
          id: 2,
          heading: 'PERSONALISED CAMPAIGNS',
          desc: 'Campaigners can create beautiful fundraising pages for their school, educational clubs, our PTA’s by uploading images and videos. You can tell your donors’ unique stories and encourage more individuals to connect and donate to your fundraising campaign.',
          list: null
        },
        {
          id: 3,
          heading: 'IT’S FREE',
          desc: 'Crowdfunding and fundraising on Ketto’s Online Platform is FREE!. <br> Yes FREE! You can <a href="https://www.ketto.org/support/plans-and-pricing.php"> start campaigns at 0% Platform Fee*</a>',
          list: null
        },
        {
          id: 4,
          heading: 'Create Your Education-Related Fundraiser in A Few Minutes',
          desc: '',
          list: [
            {
              id: 1,
              title: 'START A FUNDRAISER FOR YOUR SCHOOL',
              desc: 'Don’t worry; it’s easy! With Ketto, your school’s fundraising campaign can be set up in minutes. Just log in, tell us a few details about yourself, your story, and upload photos and videos to get started.'
            },
            {
              id: 2,
              title: 'ADD IMAGES TO YOUR CAMPAIGN STORY',
              desc: 'Uploading images and videos of students and teachers (with permission, of course!) is a fantastic way to tell your potential donors where their funds will be spent.',
              smallText: 'Learn more about clicking and uploading great crowdfunding photos.'
            },
            {
              id: 3,
              title: 'SHARE FUNDRAISER ON SOCIAL MEDIA',
              desc: 'You can share your campaign page on social media platforms, like Facebook and Twitter, and send emails to parents, students, faculty, and staff.',
              smallText: 'Learn more about sharing your online crowdfunding campaign with your network and to social media sites.'
            },
            {
              id: 4,
              title: 'GIVE THANKS',
              desc: 'People want to be appreciated! Acknowledge your supporters and give them updates about the progress of your fundraising efforts.',
              smallText: 'Check out these Pro-tips for writing crowdfunding updates.'
            },
          ]
        },
        {
          id: 5,
          heading: 'WHAT ARE THE BENEFITS OF USING KETTO FOR YOUR SCHOOL FUNDRAISING?',
          desc: '',
          list: [
            {
              id: 1,
              title: 'KEEP IT ALL',
              desc: 'At Ketto, there’s no minimum amount, deadline, penalty if your campaign doesn’t reach its crowdfunding goal. The funds you raise are yours to keep.'
            },
            {
              id: 2,
              title: 'RISK-FREE FUNDRAISING',
              desc: 'All fundraisers are scrutinised, and the documents of campaigners are verified. We strive to ensure transparency and risk-free fundraising.'
            },
            {
              id: 3,
              title: 'EXCELLENT CUSTOMER SUPPORT',
              desc: 'Whether you’re tech-savvy or not, our 24*7 support team is always available to help you throughout your fundraising journey on Ketto.'
            }
          ]
        }
      ]
    }
  ],
  searches: {
    data: `
        <a href="https://www.ketto.org/new/sip/education"> Gift Children With Education </a> |
        <a href="https://www.ketto.org/blog/twenty-five-quick-fundraising-ideas"> 25 Creative Fundraising Ideas  </a> |
        <a href="https://www.ketto.org/blog/social-impact-plan-from-ketto-is-taking-fundraising-to-the-next-level"> Social Impact Plan (SIP)  </a> |
        <a href="https://www.ketto.org/blog/what-is-sports-crowdfunding"> Sports Crowdfunding   </a>|
        <a href="https://www.ketto.org/blog/crowdfunding-for-startups-in-india"> Crowdfunding for Startups in India </a> |
        <a href="https://www.ketto.org/healthfirst"> Browse Best Health Care Plans for You </a>`
  }
};
