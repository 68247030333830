import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  /**Get from cookiee */
  getCookie(name) {
    if (typeof document !== 'undefined') {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }
  }
  /**Get encoded cookies
   * this mathod get the encoded cookies
   * and decode them
   */
  getDecodedCookies(name) {
    if (typeof document !== 'undefined') {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return JSON.parse(atob(decodeURIComponent(parts.pop().split(';').shift())));
      }
    }
  }
  /** Set document Cookie
   * @param cname: Name of cookie
   * @param cvalue: Value of cookie
   * @param exdays: Expiry of cookies in number of days
   * @param domain: Name of domain for whic cookie is to be set
  */
  setCookie(cname: string, cvalue: string, exdays: number, domain?: string): void {
    if (typeof document !== 'undefined') {
      let expires = '';
      if (exdays) {
        const date = new Date();
        date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
      }
      const domainString = domain ? `; domain=${domain}` : '';
      document.cookie = `${cname}=${cvalue || ''}${expires}; path=/${domainString}`;
    }
  }
  /** Delete cookie
   * @param name: Name of the cookie to be deleted
  */
  deleteCookie(name: string, domain?: string): void {
    if (typeof document !== 'undefined') {
      document.cookie = name + `=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/; domain=${domain || ''}`;
    }
  }
}
