import { UtilService } from './../../../shared/services/util/util.service';
import { Component, ViewChild } from '@angular/core';

import { IMainLayoutConfig } from '../../../shared/models/main-layout.config';
import { VariablesService, VwoService } from '../../../shared/services';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {

  public config: IMainLayoutConfig;
  isMobile = false;
  constructor(
    public variablesService: VariablesService,
    public utilService: UtilService,
    public vwo: VwoService
  ) {
    this.variablesService.mainLayoutConfig.subscribe({
      next: res => {
        setTimeout(() => {
          this.config = res;
        }, 0);
      }
    });
  }

  ngOnInit() {
    this.isMobile = this.utilService.isMobile();
  }

  headerLogoClickListner = (data) => {
    this.variablesService.logoOnclik.next(true);
  }

}
