export const FOOTER_SEO_CONTENT_MEMORIAL = {
  mainTitle: 'Get Help to Start A Memorial and Funeral Fundraising On Ketto',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'What is Memorial Fundraising?',
      desc: `Ketto’s Memorial Crowdfunding is the easiest way for grieving families to raise funds for funerals, memorials, and other memorial related expenses. <br> <br>
            By using Ketto's crowdfunding platform, you can easily set up a campaign page and share it with your friends, family, and social network.
            <br><br>
            Your crowdfunding donation page on Ketto will include:
            <ul>
                <li>An informational title.</li>
                <li>An attainable goal.</li>
                <li>A few high-quality photos.</li>
                <li>A brief description of the campaign or cause.</li>
            </ul>
            Crowdfunding proves to be the most effective as a fundraiser to receive donations from near and dear friends, close family, and also strangers.
            `,
      children: [
        {
          id: 1,
          heading: 'You can raise funds for the following:',
          desc: '',
          list: [
            {
              id: 1,
              title: 'EXPENSIVE FUNERAL COSTS',
              desc: 'If you require assistance with funeral costs for your loved one, turn to Ketto crowdfunding for help or support!',
              list: null
            },
            {
              id: 2,
              title: 'EXPENSIVE CREMATION COSTS',
              desc: `Cremation is less expensive than a traditional burial. However, it can be a financial burden for many families that are already grieving their recent loss.<br>
                            Is someone you care about finding it hard to arrange cremation costs? <a href="https://www.ketto.org/new/crowdfunding">Start a cremation crowdfunding campaign</a> on Ketto for them!`,
              list: null
            },
            {
              id: 3,
              title: 'TO SUPPORT THE FAMILY',
              desc: `Numerous families face financial difficulty after losing a breadwinner, husband, mother, wife, or primary provider. The family struggles even more if there are small children. <br>
                            By starting a campaign on Ketto, you can help a grieving family in need of funds.`,
              list: null
            },
            {
              id: 4,
              title: 'MEMORIAL SERVICES EXPENSE',
              desc: `Memorial services are alternatives to funeral services and are used by surviving family members to express the joy of their loved one's life. <br>
                            You can raise funds or receive donations to pay for memorial services by starting a campaign to reach out to your close network.`,
              list: null
            },
            {
              id: 5,
              title: 'MEMORIAL DEDICATIONS',
              desc: `Family and friends like to remember their loved one by dedicating a building structure, tree, school bench, or other tributes to them.<br>
                            These dedications are most often very expensive. Crowdfunding can help you pay for your loved one's dedication.<br>
                            Here are some crowdfunding campaign ideas to raise more funds for funeral costs and memorial expenditures.`,
              list: null
            },
            {
              id: 6,
              title: 'MEMORIAL DINNER',
              desc: `Bringing near and dear ones together for a memorial dinner after the passing of a loved one is an easy way to raise funds.<br>
                            As the dinner progresses, individuals can talk about pleasant things that happened in the past about their loved ones. This is an excellent way to help reduce the expenses of a funeral or memorial service while also remembering the person's life.`,
              list: null
            }
          ]
        },
        {
          id: 2,
          heading: 'Benefits of memorial and funeral crowdfunding:',
          desc: `<a href="https://www.ketto.org/crowdfunding">Online crowdfunding</a> pages can overcome geographic boundaries that impede other types of fundraising. <br>
                    Crowdfunding campaigns are low-maintenance; this allows you to concentrate on more important matters than raising finances.<br>
                    So are you ready to begin a crowdfunding campaign for the memorial? <a href="https://www.ketto.org/blog/how-to-create-a-fundraiser-online-in-5-easy-ways">Create a fundraising page</a>
                    on Ketto in minutes and start raising funds for funeral charges and memorial funds. <br>
                    Here are a few tips for raising funds for a funeral or memorial service`,
          list: [
            {
              id: 1,
              title: 'SHARE SOME VISUALS',
              desc: 'Remember, you aren\'t just raising funds; you\'re remembering your loved ones and helping other people reminisce them! Posting their photographs or videos will allow donors to feel more closely connected to your memorial fundraiser.',
              list: null
            },
            {
              id: 2,
              title: 'TELL THEIR STORY',
              desc: 'There is no need to deep-dive into the details, but you should highlight the recent years and bring forth old stories. This allows everyone to connect. Similarly, they can recall instances of their own!',
              list: null
            },
            {
              id: 3,
              title: 'GET SOCIAL ATTENTION',
              desc: 'Once you\'ve set up your online crowdfunding memorial campaign, uploaded relevant images or videos, and described your loved one\'s life, it\'s now time to share your fundraiser with the ones who knew him! You can use online social media and share the link with your friends and family.',
              list: null
            },
            {
              id: 4,
              title: 'SHARE UPDATES',
              desc: 'As you reach your fundraising goals, let all your donors know about how you\'re progressing! They will also want to learn more about how your crowdfunding campaign is going and if they can help you even more. You can also post family updates, the day, time, location of the memorial service or other events.',
              list: null
            }
          ]
        },
        {
          id: 3,
          heading: 'What Ketto Offers',
          desc: `<ul>
                        <li>Absolutely free memorial fundraising.</li>
                        <li>24x7 assistance throughout your fundraising journey.</li>
                        <li>Donations in multiple currencies from anywhere in the world.</li>
                        <li>Withdraw your funds at any point during the course of your fundraiser.</li>
                        <li>Instant updates on your fundraiser's progress on a real-time dashboard.</li>
                        <li>Accepts donations from all cards, net banking, UPI and online wallets.</li>
                    </ul>
                    Start your memorial fundraiser on Ketto today!
                    `,
          list: null
        },
      ]
    },
  ]
};
