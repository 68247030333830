import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbandonedCartComponent } from './components/abandoned-cart/abandoned-cart.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [AbandonedCartComponent],
  exports: [AbandonedCartComponent],
})
export class AbandonedCartModule { }
